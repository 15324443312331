import {useContext, useEffect, useRef, useState} from "react";
import {ContentContext} from "../../lessons-generator/lessons-generator.tsx";
import {UrlHelper} from "../../../util/url-helper.ts";
import {ReactSVG} from "react-svg";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {generateQuestionsId} from "../../questions-generator/questions-generator.tsx";
import {InputWidget} from "../../../models/contents.ts";

type SvgElement = {
    svg: Node,
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
}

const SVGDom = (props: {
    dom: Node,
    rootSvg: SVGSVGElement | null
}) => {
    const containerRef = useRef<SVGSVGElement>(null);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.appendChild(props.dom.cloneNode(true));
            containerRef.current.viewBox.baseVal.width = props.rootSvg?.viewBox.baseVal.width || 0;
            containerRef.current.viewBox.baseVal.height = props.rootSvg?.viewBox.baseVal.height || 0;
        }
    }, [containerRef.current])
    return <svg width="200" ref={containerRef}></svg>
}

const search = (target: any, filterRect: boolean): SvgElement[] => {
    const arr = [] as SvgElement[];
    svg: if (target.getBBox !== undefined) {
        if (filterRect && target.tagName !== "rect") {
            break svg;
        }
        const t = target as SVGGraphicsElement;
        const bb = t.getBBox();
        arr.push({
            id: generateQuestionsId(),
            svg: t.cloneNode(true),
            x: bb.x,
            y: bb.y,
            width: bb.width,
            height: bb.height,
        });
    }
    const children = target.children;
    for (let i = 0; i < children.length; i++) {
        arr.push(...search(children[i], filterRect));
    }
    return arr;
}

export function SvgPicker(props: {
    url: string | undefined
    addFormTextBox: (box: InputWidget.FormTextBox) => void
}) {
    if (!props.url) {
        return <></>
    }
    const content = useContext(ContentContext)
    const imgSrc = UrlHelper.normalizeUrl(props.url, content).replace(/\.(webp|jpg)$/g, ".svg")
    const [svgElements, setSvgElements] = useState([] as SvgElement[]);
    const [svgElement, setSvgElement] = useState(null as SvgElement | null);
    const [rootSvg, setRootSvg] = useState(null as SVGSVGElement | null);
    const [check, setCheck] = useState(true);

    useEffect(() => {
        fetch(imgSrc)
            .then(res => res.text())
            .then(text => {
                const parser = new DOMParser();
                setRootSvg(parser.parseFromString(text, "image/svg+xml").children[0] as SVGSVGElement);
            })
    }, [imgSrc])
    return (
        <Grid container spacing={2} sx={{my: 2}}>
            <Grid item xs={4}>
                <ReactSVG src={imgSrc}
                          onClick={(event) => {
                              setSvgElements(search(event.target as SVGPathElement, check))
                          }}/>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="select">Select Element</InputLabel>
                    <Select
                        labelId="element"
                        id="select-element"
                        label="Select Template"
                        value={svgElement?.id || ""}
                        onChange={(event) => {
                            const index = event.target.value
                            if (index) {
                                setSvgElement(svgElements.find(e => e.id === index)!)
                            }
                        }}
                    >
                        <MenuItem
                            aria-label="None" value=""
                        />
                        {svgElements.map(e => (
                            <MenuItem key={e.id}
                                      value={e.id}
                                      style={{
                                          width: 220,
                                          height: (rootSvg?.height.baseVal.value || 1) * 200 / (rootSvg?.width.baseVal.value || 1),
                                      }}
                            >
                                <SVGDom dom={e.svg} rootSvg={rootSvg}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={() => {
                    if (svgElement) {
                        props.addFormTextBox({
                            x: svgElement.x,
                            y: svgElement.y,
                            relative_w: svgElement.width,
                            relative_h: svgElement.height,
                            type: "general",
                            answer: "",
                        });
                    }
                }}>add</Button>
                <FormControlLabel
                    style={{marginLeft: 10}}
                    control={
                        <Checkbox
                            checked={check ?? false}
                            onChange={(event) => {
                                setCheck(event.target.checked)
                            }}
                        />
                    }
                    label="Rectでfilter"
                />
            </Grid>
        </Grid>
    )
}

