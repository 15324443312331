import {forwardRef, useImperativeHandle, useState} from "react";
import {Dialog, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {PATHS} from "../../App.tsx";

export type LinkDialogueRef = {
    open: () => void
}

export default forwardRef<LinkDialogueRef>((_, ref) => {
    const [open, setOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));
    return (
        <>
            <Dialog open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>Go To</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href={PATHS.LESSONS_GENERATOR}>
                                <ListItemText primary="Lessons Generator"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href={PATHS.QUESTIONS_GENERATOR}>
                                <ListItemText primary="Questions Generator"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href={PATHS.SYNTHESIZE_SPEECH}>
                                <ListItemText primary="Synthesize Speech"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </>
    )
})
