import {PauseWidget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";

function PauseWidgetForm(props: {
    value: PauseWidget
}) {
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    defaultValue={props.value.type}
                    disabled={true}
                />
            </Grid>
        </>
    );
}

export default PauseWidgetForm;
