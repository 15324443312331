import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import KnowledgeTagInput from "./knowledge-tag-input.tsx";
import {Widget} from "../../models/contents.ts";

export function QuestionsOption(props: {
    value: Widget,
    onChange: (value: Widget) => void,
}) {
    return (
        <>
            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.value.isOpen ?? false}
                            onChange={(event) => {
                                props.onChange({
                                    ...props.value,
                                    isOpen: event.target.checked,
                                })
                            }}
                        />
                    }
                    label="isOpen"
                />
            </Grid>
            <Grid item xs={3}>
                <KnowledgeTagInput
                    onChange={(value) => {
                        props.onChange({...props.value, knowledgeTagIds: value})
                    }}
                    value={props.value.knowledgeTagIds || []}
                />
            </Grid>
        </>
    );
}
