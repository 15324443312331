import {useContext, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import CreateTemplateIcon from "@mui/icons-material/BookmarkAdd";
import {generateQuestionsId} from "../questions-generator/questions-generator";
import {cloneDeep} from "lodash";
import {Widget} from "../../models/contents.ts";
import {Template, TemplatesContext} from "../../context/template.tsx";


function CreateTemplate(props: {
    widget: Widget
}) {
    const {templates, setTemplates} = useContext(TemplatesContext)

    const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = useState(false)
    const [title, setTitle] = useState('');

    const addTemplate = (title: string) => {
        // guarantees unique names
        if (!templates.some(template => template.title === title)) {
            const newTemplate: Template = {
                title: title,
                id: title + String(generateQuestionsId()),
                widget: cloneDeep(props.widget)
            }
            setTemplates(prevTemplates => [...prevTemplates, newTemplate])
            return true
        }
        else {
            console.log("Please pick new name!")
            return false
        }
    }

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // does not allow whitespace
        setTitle(event.target.value.trim())
    }

    return (
        <>
            <IconButton size="small" color="primary" onClick={() => {
                setCreateTemplateDialogOpen(true)
            }}><CreateTemplateIcon/></IconButton>
            <Dialog open={createTemplateDialogOpen}
                    onClose={() => {
                        setCreateTemplateDialogOpen(false)
                    }}
            >
                <DialogTitle>Save as Template</DialogTitle>
                <DialogContent>
                    <TextField
                        label='New Template Title'
                        value={title}
                        onChange={handleTitleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCreateTemplateDialogOpen(false)
                    }}>Cancel</Button>
                    <Button sx = {{fontWeight: 'bold'}}
                        onClick={() => {
                            if (addTemplate(title)) {
                                setCreateTemplateDialogOpen(false)
                            }
                        }}
                    >Add</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateTemplate;
