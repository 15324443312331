import {useState} from "react";
import {Button, Dialog, DialogActions, DialogTitle, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function DeleteButton(props: {
    idx: number,
    onDelete: (idx: number) => void,
}) {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    return (
        <>
            <IconButton size="small" color="error" onClick={() => {
                setDeleteDialogOpen(true)
            }}><DeleteIcon/></IconButton>
            <Dialog open={deleteDialogOpen} onClose={() => {
                setDeleteDialogOpen(false)
            }}>
                <DialogTitle>delete?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => {
                        setDeleteDialogOpen(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        props.onDelete(props.idx)
                        setDeleteDialogOpen(false)
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteButton;
