import {FormHintExplanation, MultipleChoiceWidget, Widget} from "../../models/contents.ts"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import {VoiceIdInput} from "../misc/voice-id-input.tsx"
import {Fragment, useState} from "react"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteIcon from "@mui/icons-material/Delete"
import {InputFromImage} from "../misc/input-from-image.tsx"
import HintExplanationForm from "./input-widget-form/hint-explanation-form.tsx"
import {Thumbnail} from "../misc/thumbnail.tsx"
import {QuestionsOption} from "../misc/questions-option.tsx";
import {ArrowDownward, ArrowUpward, FileCopy} from "@mui/icons-material"
import {HintExplanationFormCopyPastePanel} from "./input-widget-form/hint-explanation-form-copy-paste-panel.tsx";

export const MultipleChoiceWidgetForm = (props: {
    value: MultipleChoiceWidget,
    onChange: (value: Widget) => void,
    isQuestion: boolean,
}) => {
  if (!props.value.parameters) {
    props.onChange({
      ...props.value,
      parameters: {
        images: [],
        answers: [],
        direction: "vertical",
        image: {
          url: "",
          w: 0,
          h: 0,
        },
      },
      form: {
        images: [],
        direction: "vertical",
        original: {
          w: 0,
          h: 0,
        },
        display: {
          w: 0,
        },
      },
    } as MultipleChoiceWidget)
    return <></>
  }
  const form = props.value.form!
  const setForm = (value: MultipleChoiceWidget.Form) => {
    const newValue = { ...props.value }
    newValue.form = value
    props.onChange(newValue)
  }

  const addFormImage = (idx: number) => {
    const newFrom = { ...form }
    newFrom.images.splice(idx + 1, 0, {
      url_default: "",
      url_selected: "",
      is_answer: false,
      original: {
        w: 0,
        h: 0,
      },
      display: {
        w: 0,
      },
    })
    setForm(newFrom)
  }
  const moveFormImage = (idx: number, direction: number) => {
    const newForm = { ...form }
    const target = newForm.images[idx]
    newForm.images[idx] = newForm.images[idx + direction]
    newForm.images[idx + direction] = target
    setForm(newForm)
  }
  const changeFormImage = (idx: number, value: MultipleChoiceWidget.FormImage) => {
    const newFrom = { ...form }
    newFrom.images[idx] = value
    setForm(newFrom)
  }

  const deleteFormImage = (idx: number) => {
    const newFrom = { ...form }
    newFrom.images.splice(idx, 1)
    setForm(newFrom)
  }

  const copyFormImage = (idx: number) => {
    const newForm = { ...form }
    const copyImages = newForm.images[idx]
    newForm.images.splice(idx + 1, 0, copyImages)
    setForm(newForm)
  }

  type TabKey = "base" | "choice" | "hint"
  const [tabKey, setTabKey] = useState<TabKey>("base")
  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabKey) => {
    setTabKey(newValue)
  }

  return (
    <>
      <Grid item xs={3}>
        <TextField fullWidth label="type" value={props.value.type} disabled={true} />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id={`direction-${props.value.id}`}>direction</InputLabel>
          <Select
            labelId={`direction-${props.value.id}`}
            label="direction"
            onChange={(event) => {
              setForm({
                ...form,
                direction: event.target.value as MultipleChoiceWidget.Form["direction"],
              })
            }}
            defaultValue={form.direction}
          >
            <MenuItem value="vertical">
              <em>vertical</em>
            </MenuItem>
            <MenuItem value="horizontal">
              <em>horizontal</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <InputFromImage
          onExtract={(value) => {
            setForm({
              ...form,
              original: {
                w: value.w,
                h: value.h,
              },
              image_url: value.fileName,
            })
          }}
        />
      </Grid>
      {props.isQuestion ? (
        <>
          <QuestionsOption value={props.value} onChange={props.onChange} />
        </>
      ) : (
        <Grid item xs={5}></Grid>
      )}
      <TabContext value={tabKey}>
        <Grid item xs={12}>
          <TabList onChange={handleTabChange}>
            <Tab label="Base" value="base" />
            <Tab label="Choice" value="choice" />
            <Tab label="Hint / Explanation" value="hint" />
          </TabList>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value="base">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <VoiceIdInput
                  id={props.value.id}
                  value={form.voice_id ?? ""}
                  onChange={(voice_id) => {
                    setForm({ ...form, voice_id })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  fullWidth
                  label="text"
                  value={form.text ?? ""}
                  onChange={(event) => {
                    setForm({ ...form, text: event.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.is_markdown ?? false}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          is_markdown: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="is markdown"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  multiline
                  fullWidth
                  label="voice text"
                  value={props.value.form?.voice_text}
                  onChange={(event) => {
                    setForm({...form, voice_text: event.target.value})
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.no_icon ?? false}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          no_icon: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="no icon"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="url"
                  value={form.image_url}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({
                      ...form,
                      image_url: event.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {form.image_url && <Thumbnail url={form.image_url} />}
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="original image width"
                  type="number"
                  value={form.original?.w ?? 0}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    setForm({
                      ...form,
                      original: { w: Number(event.target.value), h: form.original?.h ?? 0 },
                    })
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="original image height"
                  type="number"
                  value={form.original?.h ?? 0}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    setForm({
                      ...form,
                      original: { w: form.original?.w ?? 0, h: Number(event.target.value) },
                    })
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="width when displayed"
                  type="number"
                  value={form.display?.w ?? 0}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    setForm({
                      ...form,
                      display: { w: Number(event.target.value) },
                    })
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
        <TabPanel value="choice">
          <Grid item xs={12} sx={{ ml: 4 }}>
            <Grid container spacing={2}>
              <Grid item>
                <AddFormImage
                  idx={0}
                  addFormImage={addFormImage}
                  moveFormImage={moveFormImage}
                  itemsLength={form.images.length}
                />
              </Grid>
              {form.images.map((input, idx) => (
                <Fragment key={idx}>
                  <Grid item>
                    <FormImageForm
                      widgetId={props.value.id}
                      idx={idx}
                      value={input}
                      onChange={changeFormImage}
                      onDelete={deleteFormImage}
                      onCopy={copyFormImage}
                    />
                  </Grid>
                  <Grid item>
                    <AddFormImage
                      idx={idx + 1}
                      addFormImage={addFormImage}
                      moveFormImage={moveFormImage}
                      itemsLength={form.images.length}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="hint">
          <Stack spacing={2}>
            <HintExplanationForm
              widgetId={`hint-${props.value.id}`}
              title="Hint"
              value={form.hint}
              onChange={(value: FormHintExplanation) => {
                setForm({
                  ...form,
                  hint: value,
                })
              }}
            />
            <HintExplanationForm
              widgetId={`explanation-${props.value.id}`}
              title="Explanation"
              value={form.explanation}
              onChange={(value: FormHintExplanation) => {
                setForm({
                  ...form,
                  explanation: value,
                })
              }}
            />
            <HintExplanationFormCopyPastePanel
              hint={form.hint}
              explanation={form.explanation}
              onPaste={(hint, explanation) => {
                setForm({
                  ...form,
                  hint: hint,
                  explanation: explanation,
                })
              }}
            />
          </Stack>
        </TabPanel>
      </TabContext>
    </>
  )
}

function AddFormImage(props: {
  idx: number
  addFormImage: (idx: number) => void
  moveFormImage: (idx: number, direction: number) => void
  itemsLength: number
}) {
  return (
    <>
      <Stack direction="row">
        <Button onClick={() => props.addFormImage(props.idx)} sx={{ textAlign: "center" }}>
          <AddCircleOutlineIcon />
          <Typography sx={{ ml: 1 }}>Add Choice</Typography>
        </Button>
        <IconButton
          size="small"
          color="primary"
          disabled={props.idx === 0}
          onClick={() => props.moveFormImage(props.idx, -1)}
          sx={{ display: props.idx === props.itemsLength ? "none" : "block" }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          disabled={props.idx === props.itemsLength - 1}
          onClick={() => props.moveFormImage(props.idx, 1)}
          sx={{ display: props.idx === props.itemsLength ? "none" : "block" }}
        >
          <ArrowDownward />
        </IconButton>
      </Stack>
    </>
  )
}

function FormImageForm(props: {
  idx: number
  widgetId: string
  value: MultipleChoiceWidget.FormImage
  onChange: (idx: number, value: MultipleChoiceWidget.FormImage) => void
  onDelete: (idx: number) => void
  onCopy: (idx: number) => void
}) {
  const [open, setOpen] = useState(false)
  const form = props.value

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="off image url"
            value={form.url_default ?? ""}
            onChange={(event) => {
              props.onChange(props.idx, { ...form, url_default: event.target.value })
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="on image url"
            value={form.url_selected ?? ""}
            onChange={(event) => {
              props.onChange(props.idx, { ...form, url_selected: event.target.value })
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.is_answer}
                onChange={(event) => {
                  props.onChange(props.idx, { ...form, is_answer: event.target.checked })
                }}
              />
            }
            label="is answer"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="original width"
            value={form.original.w ?? ""}
            onChange={(event) => {
              props.onChange(props.idx, {
                ...form,
                original: { ...form.original, w: Number(event.target.value) },
              })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="original height"
            value={form.original.h ?? ""}
            onChange={(event) => {
              props.onChange(props.idx, {
                ...form,
                original: { ...form.original, h: Number(event.target.value) },
              })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="display width"
            value={form.display.w ?? ""}
            onChange={(event) => {
              props.onChange(props.idx, {
                ...form,
                display: { ...form.display, w: Number(event.target.value) },
              })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <InputFromImage
            onExtract={(value) => {
              props.onChange(props.idx, {
                ...form,
                original: {
                  w: value.w,
                  h: value.h,
                },
                url_default: value.fileName,
              })
            }}
          />
          <Button onClick={() => setOpen(true)}>
            <DeleteIcon />
          </Button>
          <IconButton size="small" color="primary" onClick={() => {
            props.onCopy(props.idx)
          }}><FileCopy /></IconButton>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete it</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false)
              props.onDelete(props.idx)
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
