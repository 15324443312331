import {
    HandWritingV2Widget,
    HandWritingWidget,
    InputWidget,
    MultipleChoiceWidget,
    Widget,
    WidgetType
} from "../../models/contents.ts";
import InputWidgetForm from "../widget-form/input-widget-form/input-widget-form.tsx";
import {useMemo} from "react";
import {Questions} from "../../models/Questions.ts";
import {MultipleChoiceWidgetForm} from "../widget-form/multiple-choice-widget-form.tsx";
import HandWritingWidgetForm from "../widget-form/hand-writing-widget-form.tsx";
import HandWritingV2WidgetForm from "../widget-form/hand-writing-v2-widget-form.tsx";

function QuestionsWidgetForm(props: {
    widget: Widget,
    onChange: (value: Widget) => void,
    questions: Questions,
}) {
    const {widget, onChange} = props
    return useMemo(() => {
        switch (widget.type) {
            case WidgetType.Input:
                return (
                    <InputWidgetForm value={widget as InputWidget} onChange={onChange} isQuestion={true}/>
                )
            case WidgetType.MultipleChoice:
                return (
                    <MultipleChoiceWidgetForm value={widget as MultipleChoiceWidget} onChange={onChange}
                                              isQuestion={true}/>
                )
            case WidgetType.HandWriting:
                return (
                    <HandWritingWidgetForm value={widget as HandWritingWidget} onChange={onChange} isQuestion={true}/>
                )
            case WidgetType.HandWritingV2:
                return (
                    <HandWritingV2WidgetForm value={widget as HandWritingV2Widget} onChange={onChange} isQuestion={true}/>
                )
            default:
                return <></>
        }
    }, [widget,
        props.questions.baseKnowledgeTagId,
    ])
}

export default QuestionsWidgetForm
