import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {VoiceIdList} from "../../models/voice-id.ts";

export const VoiceIdInput = (
    props: {
        id: string,
        onChange: (value: string | undefined) => void,
        value: string,
    }
) => {
    return (
        <FormControl fullWidth>
            <InputLabel id={`voice-id-${props.id}`}>Voice ID</InputLabel>
            <Select
                labelId={`voice-id-${props.id}`}
                label="Voice ID"
                onChange={(event) => {
                    const v = event.target.value as string
                    props.onChange(v ? v : undefined)
                }}
                value={props.value || ""}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {
                    VoiceIdList.map((key) => (
                        <MenuItem key={key} value={key}>{key}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
