import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SelectCheckbox(props: {
    id: string, 
    checked: boolean,
    handleSelect: Function,
    setSelectedWidgetsSet: React.Dispatch<React.SetStateAction<Set<string>>>
  }){
  // when checkbox is clicked, flips the boolean checked/unchecked
  const handleChange = () => {
    props.handleSelect(!props.checked, props.id)
  }
  return (
    <div>
      <FormControlLabel
        label="Select"
        control={
          <Checkbox
            checked={props.checked}
            onChange={handleChange}
          />
        }
      />
    </div>
  );
}