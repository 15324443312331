import {Button, Card, InputBase,} from "@mui/material";
import {useState} from "react";

function AddWidget(props: { moveWidget: (to: number) => void }
) {
    const [to, setTo] = useState<number | undefined>(undefined)
    return (
        <>
            <Card variant="outlined" sx={{p:1, width: 120, display: "flex"}}>
                <InputBase
                    fullWidth={true}
                    placeholder="index"
                    inputProps={{'aria-label': 'go to'}}
                    value={to}
                    onChange={(event) => {
                        const newTo = Number(event.target.value)
                        if (newTo) {
                            setTo(newTo)
                        } else {
                            setTo(undefined)
                        }
                    }}
                />
                <Button onClick={() => {
                    const newTo = to
                    if (newTo) {
                        props.moveWidget(newTo)
                        setTo(undefined)
                    }
                }}>move
                </Button></Card>
        </>
    )
}

export default AddWidget
