import {Autocomplete, Button, TextField} from "@mui/material";
import {useState} from "react";
import {normalizeWidget} from "../../common/widget.ts";
import {Questions, QuestionsWidget} from "../../models/Questions.ts";

const GlobalTagInput = (props: {
    selectedWidgetsSet: Set<string>
    questions: Questions
    setQuestions: React.Dispatch<React.SetStateAction<Questions>>
}) => {
    // keeps track of globalTagIds, needed for dynamic rerendering
    const [globalTagIds, setGlobalTagIds] = useState<string[]>([])

    const [history, setHistory] = useState(() => {
        const _history = localStorage.getItem("knowledgeTags");
        if (_history !== null) {
            return _history.split(",");
        }
        return [];
    });

    const addHistory = (h: string[]) => {
        const currentHistory = localStorage.getItem("knowledgeTags")?.split(",") || [];
        const newHistory = new Set(currentHistory);
        for (const v of h) {
            newHistory.add(v);
        }
        if (newHistory.size !== currentHistory.length) {
            localStorage.setItem("knowledgeTags", [...newHistory].join(","));
            setHistory([...newHistory]);
        }
    };

    // Loop through all widgets and apply the global tags to selected ones
    const addTagsToWidgets = () => {
        // make copy of newQuestions
        const newQuestions = {...props.questions}
        // loop through all newQuestions.widgets
        newQuestions.widgets = newQuestions.widgets.map((widget) => {
            // If current widget is selected, apply global tags
            if (props.selectedWidgetsSet.has(widget.id)) {
                // default: set newIds to just the global knowledge tags selected in top menu
                let newIds = globalTagIds
                // if the widget already has global knowledge tags, combine old and new knowledgeTagIds lists
                // use set to remove any duplicates
                if (widget.knowledgeTagIds) {
                    newIds = Array.from(new Set([...widget.knowledgeTagIds, ...globalTagIds]));
                }
                // return the normalizedWidget with the newIds as QuestionsWidget
                return normalizeWidget(
                    { ...widget, knowledgeTagIds: newIds},
                    newQuestions
                ) as QuestionsWidget
            }
            // If current widget is not selected, make no changes. Simply return tags.
            return widget
        })
        // setQuestions to newQuestions
        props.setQuestions(newQuestions)
        // clear top tag list after questions have been set with new tags
        setGlobalTagIds([])
    };

    return (
        <div>
            <Autocomplete
                multiple
                freeSolo
                options={history}
                value={globalTagIds}
                onChange={(_, newValue) => {
                    setGlobalTagIds(newValue);
                    addHistory(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Knowledge Tags (Enter to add!)"
                        placeholder="Knowledge Tags (Enter to add!)"
                    />
                )}
            />
            <Button
                variant="contained"
                color="secondary"
                onClick={addTagsToWidgets}
                sx={{my: 2, color: "white", display: "block"}}
            >Apply Tags
            </Button>
            </div>
    );
};

export default GlobalTagInput;
