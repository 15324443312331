import {createContext, Dispatch, FC, ReactNode, SetStateAction, useState} from "react";
import {Contents} from "../models/contents";
import {Questions} from "../models/Questions";
import {isContents, isQuestions} from "../util/url-helper";
import {TreeViewBaseItem} from "@mui/x-tree-view";
import {openDB} from "idb";


export const ExplorerContext = createContext<{
    open: boolean,
    isStarted: boolean,
    fileMap: {[key: string]: FileData},
    fileTree: TreeViewBaseItem<ExtendedTreeItemProps>[],
    toggleExplorer: () => void,
    load: (json: any) => void,
    handleShowDirectoryPicker: () => Promise<void>,
    saveContents: (content: Contents | Questions) => Promise<void>,
    saveFile: () => Promise<void>,
    errorMessage: string | null,
    getQuestions: (baseKnowledgeTagId: string) => Promise<Questions>,
    moveFile: (sourcePath: string, destinationPath: string, isDelete: boolean) => Promise<void>,

}>({} as any)

export type FileType = 'json' | 'media' | 'folder';

export type ExtendedTreeItemProps = {
  id: string;
  label: string;
  fileType?: FileType;
  contents?: Contents | Questions;
};

export interface FileData {
    id: string; // (Required) String that uniquely identifies the file
    name: string; // (Required) Full name, e.g. `MyImage.jpg`
    ext?: string; // File extension, e.g. `.jpg`
    isDir?: boolean; // Is a directory, default: false
    size?: number; // File size in bytes
    childrenCount?: number; // Number of files inside of a folder (only for folders)
    // Default preview overriding
    color?: string; // Color to use for this file
    icon?: string | any; // Icon to use for this file
    thumbnailUrl?: string; // Automatically load thumbnail from this URL
    parentId?: string;
    childrenIds?: TreeViewBaseItem[];
    contents?: Contents | Questions;
    path: string;
    [property: string]: any; // Any other user-defined property
}

interface ExplorerProviderProps {
    contents: Contents | Questions,
    setContents: Dispatch<SetStateAction<Contents>> | Dispatch<SetStateAction<Questions>>,
    children: ReactNode;
}

interface FileMap {
    [fileId: string]: FileData;
}

// ディレクトリハンドルの保存
async function saveHandle(handle: FileSystemDirectoryHandle): Promise<void> {
    const db = await openDB('handles', 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains('fileHandles')) {
                db.createObjectStore('fileHandles');
            }
        },
    });
    const tx = db.transaction('fileHandles', 'readwrite');
    await tx.objectStore('fileHandles').put(handle, 'directory');
    tx.commit();
}

// 保存されたディレクトリハンドルの再取得
async function getSavedDirectoryHandle(): Promise<FileSystemDirectoryHandle | undefined> {
    try {
        const db = await openDB('handles', 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains('fileHandles')) {
                    db.createObjectStore('fileHandles');
                }
            },
        });
        const tx = db.transaction('fileHandles');
        const handle = await tx.objectStore('fileHandles').get('directory');
        tx.commit();
        if (handle) {
            // すでにユーザーの許可が得られているかをチェック
            let permission = await handle.queryPermission({mode: 'readwrite'});
            if (permission !== 'granted') {
                // ユーザーの許可が得られていないなら、許可を得る（ダイアログを出す）
                permission = await handle.requestPermission({mode: 'readwrite'});
                if (permission !== 'granted') {
                    throw new Error('ユーザーの許可が得られませんでした。');
                }
            }
            return handle;
        }
        return undefined
    } catch (error) {
        console.error('Error while getting directory handle:', error);
        return undefined;
    }
}

export const ExplorerContextProvider: FC<ExplorerProviderProps> = ({contents, setContents, children}) => {

    const [open, setOpen] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const toggleExplorer = () => {
        setOpen(!open);
    };
    const [rootHandler, setRootHandler] = useState<FileSystemDirectoryHandle | null>(null);
    const [fileMap, setFileMap] = useState<FileMap>({});
    const [fileTree, setFileTree] = useState<TreeViewBaseItem<ExtendedTreeItemProps>[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Load a json file into contents
    const load = (contents: Contents | Questions) => {
        if (isContents(contents)) {
            (setContents as Dispatch<SetStateAction<Contents>>)(contents);
        } else if (isQuestions(contents)) {
            (setContents as Dispatch<SetStateAction<Questions>>)(contents);
        }
    };

    const update = async (handle: FileSystemDirectoryHandle) => {
        try {
            // construct fileMap object with type fileData
            const fileMap: { [key: string]: FileData } = {};
            // getFilesBFS returns a AsyncGenerator object that holds all of the File promises and the return value for rootId
            const rootFolder = await getFilesDFS(handle, "", "/", fileMap)
            if (rootFolder) {
                setFileTree([rootFolder]);
            }
            setFileMap(fileMap);
        } catch (error) {
            console.error('Error while selecting directory:', error);
        }
    }
    const handleShowDirectoryPicker = async () => {
        if (!isStarted) {
            const handle = await getSavedDirectoryHandle();
            if (handle) {
                setRootHandler(handle);
                update(handle);
                setIsStarted(true);
                return;
            }
        }

        const directoryHandle = await showDirectoryPicker();
        await saveHandle(directoryHandle);
        setRootHandler(directoryHandle);
        await update(directoryHandle);
    };

    const generateUniqueId = () => crypto.randomUUID();

    // Input; handle, parentId, path, fileMap. Process: Recursive DFS. Returns TreeViewBaseItem while loading fileData into fileMap.
    const getFilesDFS = async (
        curr: FileSystemDirectoryHandle | FileSystemFileHandle,
        parentId: string,
        path: string,
        fileMap: FileMap
    ): Promise<TreeViewBaseItem<ExtendedTreeItemProps>> => {
        // create unique ID
        const id = generateUniqueId();
        const isDir = curr.kind === "directory";

        // if the handle is a directory handle
        if (isDir) {
            let children: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
            // for all children handles, recursively call DFS
            for await (const handle of curr.values()) {
                const childPath = path.concat(curr.name, "/");
                const childTreeView = await getFilesDFS(handle, id, childPath, fileMap)
                children.push(childTreeView);
            }
            children.sort((a, b) => a.label.localeCompare(b.label));
            fileMap[id] = {
                id: id,
                name: curr.name,
                isDir: true,
                parentId: parentId,
                children: children,
                path: path
            };
            return {id: id, label: curr.name, children: children, fileType: 'folder'} as TreeViewBaseItem<ExtendedTreeItemProps>;
        }
        else {
            const fileExt = curr.name.split('.').pop();
            let contents: Contents | Questions | null = null;
            if (fileExt === "json") {
                    contents = await fileToContentsOrQuestions(curr);
                    fileMap[id] = {
                        id: id,
                        name: curr.name,
                        isDir: false,
                        ext: fileExt,
                        parentId: parentId,
                        path: path,
                        contents: contents || undefined
                    };
                    return {id: id, label: curr.name, fileType: 'json', contents: contents} as TreeViewBaseItem<ExtendedTreeItemProps>;
            }
            else {
                fileMap[id] = {
                    id: id,
                    name: curr.name,
                    isDir: false,
                    ext: fileExt,
                    parentId: parentId,
                    path: path,
                };
                return {id: id, label: curr.name, fileType: 'media'} as TreeViewBaseItem<ExtendedTreeItemProps>;
            }
        }
    }

    const getFileSystemFileHandle = async (path: string): Promise<FileSystemFileHandle> => {
        if (!rootHandler) {
            throw new Error('Root directory handle not found.');
        }
        let curr = rootHandler;
        const segments = path.split('/')
        for (let i = 0; i < segments.length - 1; i++) {
            if(segments[i] === "") {
                continue;
            }
            curr = await curr.getDirectoryHandle(segments[i], {create: true});
        }
        return await curr.getFileHandle(segments[segments.length - 1], {create: true});
    }

    const getFileSystemDirctoryHandle = async (path: string): Promise<FileSystemDirectoryHandle> => {
        if (!rootHandler) {
            throw new Error('Root directory handle not found.');
        }
        let curr = rootHandler;
        const segments = path.split('/')
        for (let i = 0; i < segments.length; i++) {
            if(segments[i] === "") {
                continue;
            }
            curr = await curr.getDirectoryHandle(segments[i], {create: true});
        }
        return curr;
    }

    const moveFile = async (sourcePath: string, destinationPath: string, isDelete: boolean) => {
        if (!rootHandler) {
            throw new Error('Root directory handle not found.');
        }

        const sourceHandle = await getFileSystemFileHandle(sourcePath);
        const file = await sourceHandle.getFile();

        const destinationHandle = await getFileSystemFileHandle(destinationPath);
        const writable = await destinationHandle.createWritable()
        await writable.write(await file.arrayBuffer());
        await writable.close();

        if (isDelete) {
            const sourceDir = sourcePath.substring(0, sourcePath.lastIndexOf('/'));
            const sourceDirHandle = await getFileSystemDirctoryHandle(sourceDir);
            await sourceDirHandle.removeEntry(sourcePath.substring(sourcePath.lastIndexOf('/') + 1));
        }
    }

    const fileToContentsOrQuestions = async (curr: FileSystemFileHandle): Promise<Contents | Questions | null> => {
        try {
            // getFile() returns a promise
            const file = await curr.getFile();
            const text = await file.text();
            const json = JSON.parse(text);
            if (isContents(json)) {
                return json as Contents;
            }
            if (isQuestions(json)) {
                return json as Questions;
            }
            console.error('Parsed JSON is neither Contents nor Questions:', json);
            return null;
        } catch (error) {
            console.error('Error processing file:', error);
            return null;
        }
    }

    const getQuestionsFileHandle = async (baseKnowledgeTagId: string): Promise<FileSystemFileHandle> => {
        const directoryHandle = rootHandler;
        if (!directoryHandle) {
            console.error('Directory handle not found.');
            throw new Error('Directory handle not found.');
        }
        const ldh = await directoryHandle.getDirectoryHandle('q', {create: true});
        const level1 = await ldh.getDirectoryHandle(baseKnowledgeTagId.substring(0, 3), {create: true});

        let finalDirHandle = level1;
        if (baseKnowledgeTagId.length > 3) {
            const level2 = await level1.getDirectoryHandle(baseKnowledgeTagId.substring(0, 5), {create: true});
            finalDirHandle = level2;
            if (baseKnowledgeTagId.length > 5) {
                const level3 = await level2.getDirectoryHandle(baseKnowledgeTagId.substring(0, 7), {create: true});
                finalDirHandle = level3;
                if (baseKnowledgeTagId.length > 7) {
                    finalDirHandle = await level3.getDirectoryHandle(baseKnowledgeTagId, {create: true});
                }
            }
        }
        return await finalDirHandle.getFileHandle(`${baseKnowledgeTagId}.json`, {create: true});
    }

    const getQuestions = async (baseKnowledgeTagId: string): Promise<Questions> => {
        const fileHandle = await getQuestionsFileHandle(baseKnowledgeTagId);
        const contents = await fileToContentsOrQuestions(fileHandle);
        if (isQuestions(contents)) {
            return contents as Questions;
        }
        throw new Error('Error getting')
    }

    const saveContents = async (_c: Contents | Questions) => {
        const directoryHandle = rootHandler;
        if (!directoryHandle) {
            console.error('Directory handle not found.');
            return;
        }

        let fileHandle;

        try {
            if (isContents(_c)) {
                if (_c.lessonId && _c.lessonVersion) {
                    const ldh = await directoryHandle.getDirectoryHandle('l', { create: true });
                    const lidh = await ldh.getDirectoryHandle(_c.lessonId, { create: true });
                    const vdh = await lidh.getDirectoryHandle(`${_c.lessonVersion}`, { create: true });
                    fileHandle = await vdh.getFileHandle('lesson.json', { create: true });
                } else {
                    throw new Error('Missing lessonId or lessonVersion in _c. Please provide both.');
                }
            } else if (isQuestions(_c)) {
                if (_c.baseKnowledgeTagId) {
                    fileHandle = await getQuestionsFileHandle(_c.baseKnowledgeTagId);
                } else {
                    throw new Error('Missing baseKnowledgeTagId in questions. Please provide a baseKnowledgeTagId.');
                }
            }

            if (fileHandle) {
                // convert _c to JSON string
                const contentsJSON = JSON.stringify(_c, null, 2);
                // create a writable stream
                const writable = await fileHandle.createWritable();
                // write contents to the file
                await writable.write(contentsJSON);
                // close the file
                await writable.close();
            }
            setErrorMessage(null);
        }
        catch (error: any) {
            console.error('Error saving file:', error.message);
            setErrorMessage(error.message as string); // Update state with error message
        }
    };

    const saveFile = () => {
        return saveContents(contents);
    }

    return (
        <ExplorerContext.Provider value={{
            open,
            isStarted,
            fileMap,
            fileTree,
            toggleExplorer,
            load,
            handleShowDirectoryPicker,
            saveFile,
            errorMessage,
            getQuestions,
            moveFile,
            saveContents,
        }}>
            {children}
        </ExplorerContext.Provider>
    );
}
