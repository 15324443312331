import {Contents} from "../../models/contents.ts";
import {useState} from "react";
import {httpClient} from "../../util/axios-helper.ts";
import CommonAppBar from "../misc/common-app-bar.tsx";

function LessonsAppBar(props: {
    load: (contents: Contents) => void,
    clear: () => void,
    normalizeContents: () => Contents,
}) {
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
    const [normalizedContents, setNormalizedContents] = useState<Contents | undefined>(undefined)

    const exportContents = () => {
        const contents = props.normalizeContents()
        const blob = new Blob([JSON.stringify(contents, null, 2)], {type: "application/json"})
        const url = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `${contents.grade}_${contents.unit}_${contents.lesson}_lesson.json`
        a.click()
    }

    const previewContents = async () => {
        const contents = props.normalizeContents()
        const blob = new Blob([JSON.stringify(contents, null, 2)], {type: "application/json"})
        let versionSuffix = ""
        if (contents.lessonVersion && contents.lessonVersion > 1) {
            versionSuffix = `_${contents.lessonVersion}`
        }
        const previewPath = `pv/${Math.floor(new Date().getTime() / 1000)}_${contents.grade}_${contents.unit}${versionSuffix}_${contents.lesson}.json`
        await httpClient.postForm(
            "/api/v1/upload_file",
            {
                file: blob,
                filePath: previewPath,
            }
        )
        setPreviewUrl(`${import.meta.env.VITE_BASE_LESSON_URL}/${previewPath}`)
        setNormalizedContents(contents)
    }

    return (
        <CommonAppBar
            title="Lessons Generator"
            color="primary"
            load={props.load}
            clear={props.clear}
            exportContents={exportContents}
            previewContents={previewContents}
            previewUrl={previewUrl}
            data={normalizedContents}
        />
    )
}

export default LessonsAppBar;
