import {TitleWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";
import React from "react";

function TitleWidgetForm(props: {
    value: TitleWidget
    onChange: (value: Widget) => void
}) {
    const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = {...props.value}
        newValue.parameters =
            Object.assign({}, {
                text: event.target.value
            })
        props.onChange(newValue)
    }
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    defaultValue={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    fullWidth
                    label="text"
                    defaultValue={props.value.parameters?.text}
                    onChange={_onChange}
                />
            </Grid>
        </>
    );
}

export default TitleWidgetForm;
