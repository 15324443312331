import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {WidgetType} from "../../models/contents.ts";

function AddWidget(props: {
    index: number,
    addWidget: (index: number, type: WidgetType) => void,
    targetTypes: WidgetType[],
                   }
) {
    return (
        <Box>
            <FormControl size="small" sx={{minWidth:120}}>
                <InputLabel id={`add-widget-${props.index}`}>Type</InputLabel>
                <Select
                    labelId={`add-widget-${props.index}`}
                    label="type"
                    onChange={
                        (event) => {
                            const type = event.target.value
                            if (type) {
                                props.addWidget(props.index, event.target.value as WidgetType)
                            }
                            event.target.value = ""
                        }
                    }
                    value={""}
                    sx={{ml: 1}}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {props.targetTypes.map((key) => (
                        <MenuItem key={key} value={key}>{key}</MenuItem>
                    ))}
                </Select>
                {
                    props.index === 0
                        ? <FormHelperText>Choose a widget type</FormHelperText>
                        : <FormHelperText>Add a new Widget under this widget. </FormHelperText>
                }
            </FormControl>
        </Box>
    )
}

export default AddWidget
