import {normalizeWidget} from "../../common/widget.ts";
import {cloneDeep} from "lodash";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Container,
    Fab,
    IconButton,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import AddWidget from "../misc/add-widget.tsx";
import {TransitionGroup} from "react-transition-group";
import {ArrowDownward, ArrowUpward, FileCopy} from "@mui/icons-material";
import DeleteButton from "../misc/delete-widget-button.tsx";
import MoveWidget from "../misc/move-widget.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Questions, QuestionsWidget} from "../../models/Questions.ts";
import {Widget, WidgetType} from "../../models/contents.ts";
import QuestionsAppBar from "./questions-app-bar.tsx";
import QuestionsWidgetForm from "./questions-widget-form.tsx";
import {useEffect, useState} from "react";
import {ShuffleId} from "../misc/shuffle-id.tsx";
import SelectCheckbox from "../misc/select-checkbox.tsx";
import BulkDelete from "../misc/bulk-delete.tsx";
import SelectAll from "../misc/select-all.tsx";
import GlobalTagInput from "../misc/global-tag-input.tsx";
import axios from "axios";
import {ContentContext} from "../lessons-generator/lessons-generator.tsx";
import CreateTemplateButton from "../misc/create-template-button.tsx";
import UseTemplateButton from "../misc/use-template-button.tsx";
import {TemplatesContextProvider} from "../../context/template.tsx";
import {ExplorerPanel} from "../file-explorer/explorer-panel.tsx";
import { ExplorerContextProvider } from "../../context/explorer.tsx";

export function generateQuestionsId() {
    return Math.random().toString(32).substring(2)
}

export function generateDefaultQuestions(): Questions {
    return {
        baseKnowledgeTagId: "",
        additionalKnowledgeTagIds: [],
        widgets: [],
    }
}

function QuestionsAddWidget(props: {
    index: number
    addWidget: (index: number, widgetType: WidgetType) => void
}) {
    return (
        <AddWidget
            index={props.index}
            addWidget={props.addWidget}
            targetTypes={[
                WidgetType.Input,
                WidgetType.MultipleChoice,
                WidgetType.HandWriting,
                WidgetType.HandWritingV2,
            ]}
        />
    )
}

export function QuestionsGenerator() {

    const [questions, setQuestions] = useState<Questions>(() => {
        const contentsUrl = new URLSearchParams(location.search).get('ct')
        if (contentsUrl) {
            axios
                .get(contentsUrl)
                .then((res) => {
                    setQuestions(res.data as Questions)
                    console.log(`Successfully loaded from URL (${contentsUrl}).`)
                })
                .catch((err) => {
                    console.error(`Failed to load from URL (${contentsUrl}).`, err)
                })
            return generateDefaultQuestions()
        }

        const loaded = localStorage.getItem("questions")
        if (loaded) {
            return JSON.parse(loaded) as Questions
        }
        return generateDefaultQuestions()
    })
    useEffect(() => {
        localStorage.setItem("questions", JSON.stringify(questions))
    })


    // keeps track of selectedWidgets
    const [selectedWidgetsSet, setSelectedWidgetsSet] = useState(new Set<string>());

    const handleSelect = (checked: boolean, id: string) => {
        if (checked) {
            setSelectedWidgetsSet(prevSet => new Set(prevSet).add(id))
        }
        // delete function returns bool, so we return the Set itself
        else {
            setSelectedWidgetsSet(prevSet => {
                const newSet = new Set(prevSet);
                newSet.delete(id);
                return newSet;
            })
        }
    }

    const handleSelectAll = (allChecked: boolean) => {
        if (allChecked) {
            const newSet = new Set<string>
            for (let i = 0; i < questions.widgets.length; i++) {
                newSet.add(questions.widgets[i].id)
            }
            setSelectedWidgetsSet(newSet)
        }
        else {
            setSelectedWidgetsSet(new Set<string>)
        }
    }

    const addWidget = (index: number, widgetType: WidgetType) => {
        const newQuestions = {...questions}
        newQuestions.widgets.splice(index, 0, {
            id: generateQuestionsId(),
            type: widgetType as WidgetType.Input,
            isOpen: true,
        })
        setQuestions(newQuestions)
    }

    const changeWidget = (value: Widget) => {
        const newQuestions = {...questions}
        newQuestions.widgets[newQuestions.widgets.findIndex((w) => w.id === value.id)] =
            normalizeWidget(value, newQuestions) as QuestionsWidget;
        setQuestions(newQuestions)
    }

    const deleteWidget = (index: number) => {
        const newQuestions = {...questions}
        newQuestions.widgets.splice(index, 1)
        setQuestions(newQuestions)
    }

    const deleteWidgets = () => {
        // deep copy of contents
        let newQuestions = {...questions}
        // filter for the widgets in newQuestions.widgets list that includes widgets NOT in the selectedWidgetsSet
        // in other words, filter out the widgets in the selectedWidgetsSet from newQuestions.widgets
        newQuestions.widgets = newQuestions.widgets.filter(widget => !selectedWidgetsSet.has(widget.id))
        // setQuestions state with newQuestions
        setQuestions(newQuestions)
    }

    const normalizeQuestions = (): Questions => {
        const newQuestions = {...questions}
        for (let i = 0; i < newQuestions.widgets.length; i++) {
            let newWidget = newQuestions.widgets[i]
            newQuestions.widgets[i] = normalizeWidget(newWidget, questions) as QuestionsWidget
        }
        setQuestions(newQuestions)
        return newQuestions
    }

    const moveWidget = (index: number, direction: number) => {
        const newQuestions = {...questions}
        const tmp = newQuestions.widgets[index]
        newQuestions.widgets[index] = newQuestions.widgets[index + direction]
        newQuestions.widgets[index + direction] = tmp
        setQuestions(newQuestions)
    }

    const fixedMoveWidget = (from: number, to: number) => {
        if (from === to) return
        if (from < 0 || from >= questions.widgets.length) return
        const newQuestions = {...questions}
        const tmp = newQuestions.widgets[from]
        newQuestions.widgets.splice(from, 1)
        newQuestions.widgets.splice(to, 0, tmp)
        setQuestions(newQuestions)
    }

    const copyWidget = (index: number) => {
        const newQuestions = {...questions}
        const newWidget = cloneDeep(newQuestions.widgets[index])
        newWidget.id = Math.random().toString(32).substring(2)
        newQuestions.widgets.splice(index + 1, 0, newWidget)
        setQuestions(newQuestions)
        normalizeQuestions()
    }

    const clearWidgets = () => {
        setQuestions(generateDefaultQuestions())
    }

    return (
        <ContentContext.Provider value={questions}>
        <Box sx={{bgcolor: "#F5F5F5", pb: 2}}>
            <ExplorerContextProvider contents={questions} setContents={setQuestions}>
            <ExplorerPanel />
            <Box >
                <QuestionsAppBar
                    load={(newQuestions: Questions) => {
                        newQuestions.widgets.forEach((widget) => {
                            if (!widget.id) {
                                widget.id = Math.random().toString(32).substring(2)
                            }
                        })
                        setQuestions(newQuestions)
                    }}
                    clear={clearWidgets}
                    normalizeQuestions={normalizeQuestions}
                />
                <Container maxWidth="xl">
                    <Grid container spacing={2} sx={{my: 2}}>
                        <Grid item xs={2}>
                            <TextField label="Base KnowledgeTag" value={String(questions.baseKnowledgeTagId) || "U0X0X0X"}
                                    sx={{bgcolor: "white"}}
                                    onChange={
                                        (event) => {
                                            const newQuestions = {...questions}
                                            newQuestions.baseKnowledgeTagId = event.target.value
                                            setQuestions(newQuestions)
                                        }
                                    }/>
                        </Grid>
                        <Grid item xs={2}>
                            <ShuffleId contents={questions} setContents={(questions) => {
                                setQuestions(questions as Questions)
                            }}/>
                        </Grid>
                        <Grid item xs={2}>
                            <BulkDelete deleteWidgets={deleteWidgets}/>
                        </Grid>
                        <Grid item xs={2}>
                            <SelectAll id="select-all" allChecked={selectedWidgetsSet.size === questions.widgets.length} handleSelectAll={handleSelectAll}/>
                        </Grid>
                        <Grid item xs={2}>
                            <GlobalTagInput selectedWidgetsSet={selectedWidgetsSet} questions={questions} setQuestions={setQuestions}/>
                        </Grid>
                    </Grid>
                    {
                        questions.widgets.length === 0
                            ? <QuestionsAddWidget index={0} addWidget={addWidget}/>
                            : <></>
                    }
                    <TransitionGroup>
                        {
                            questions.widgets.map((widget, idx) => (
                                <Collapse key={widget.id}>
                                    <Card key={widget.id} sx={{my: 2, p: 2,}} variant="outlined">
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <QuestionsWidgetForm widget={widget} questions={questions}
                                                                    onChange={changeWidget}/>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Box sx={{pr: 2}}><Typography color="gray">{idx}</Typography></Box>
                                            <IconButton size="small" color="primary" disabled={idx === 0} onClick={() => {
                                                moveWidget(idx, -1)
                                            }}><ArrowUpward/></IconButton>
                                            <IconButton size="small" color="primary"
                                                        disabled={idx === questions.widgets.length - 1} onClick={() => {
                                                moveWidget(idx, 1)
                                            }}><ArrowDownward/></IconButton>
                                            <DeleteButton idx={idx} onDelete={deleteWidget}/>
                                            <IconButton size="small" color="primary" onClick={() => {
                                                copyWidget(idx)
                                            }}><FileCopy/></IconButton>
                                            <TemplatesContextProvider contents={questions} setContents={setQuestions}>
                                                <CreateTemplateButton widget={widget}/>
                                                <UseTemplateButton idx={idx + 1}/>
                                            </TemplatesContextProvider>
                                            <QuestionsAddWidget index={idx + 1} addWidget={addWidget}/>
                                            <MoveWidget moveWidget={(to: number) => {
                                                fixedMoveWidget(idx, to)
                                            }}/>
                                            <Box sx={{pl: 2}}><Typography color="gray">ID: {widget.id}</Typography></Box>
                                            <SelectCheckbox id ={widget.id} checked={selectedWidgetsSet.has(widget.id)} handleSelect={handleSelect} setSelectedWidgetsSet={setSelectedWidgetsSet}/>
                                        </CardActions>
                                    </Card>
                                </Collapse>
                            ))
                        }
                    </TransitionGroup>
                </Container>
                <Fab color="secondary" aria-label="up" size="small" sx={{position: "fixed"}} style={{
                    "bottom": "1rem",
                    "right": "2rem"
                }} onClick={() => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }}>
                    <KeyboardArrowUpIcon/>
                </Fab>
            </Box>
            </ExplorerContextProvider>
        </Box>
        </ContentContext.Provider>
    );
}
