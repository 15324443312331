import {AppBar, Box, Button, Container, Grid, TextField, Toolbar, Typography} from "@mui/material";
import {createRef, useState} from "react";
import {httpClient} from "../../util/axios-helper.ts";
import {VoiceIdInput} from "./voice-id-input.tsx";
import LinkDialogue, {LinkDialogueRef} from "./link-dialogue.tsx";

export type SynthesizeSpeechParams = {
    text: string
    fileName: string
    voiceId: string
}

export function SynthesizeSpeech() {

    const [params, setContents] = useState<SynthesizeSpeechParams>({
        text: "",
        voiceId: "",
        fileName: "",
    })

    const [audioSrc, setAudioSrc] = useState("")

    const linkDialogueRef = createRef<LinkDialogueRef>()

    const playSpeech = async () => {
        const result = await httpClient.get(
            "/api/v1/voice",
            {
                params: {
                    text: params.text,
                    voiceId: params.voiceId
                },
                responseType: 'blob',
            },
        )
        const blob = new Blob([result.data], {type: result.data.type})
        const file = URL.createObjectURL(blob)
        setAudioSrc(file);
        const a = document.createElement("a")
        a.href = file
        a.download = `${params.fileName ?? "voice"}.mp3`
        a.click()
    }
    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: "none", md: "flex"},
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            Mathmaji Synthesize Speech
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: "flex", md: "flex"}}}>
                            <Button
                                onClick={() => {
                                    linkDialogueRef.current?.open()
                                }}
                                sx={{my: 2, color: "white", display: "block"}}
                            >Go to
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{my: 2}}>
                    <Grid item xs={3}>
                        <VoiceIdInput
                            id="synthesize-speech"
                            value={params.voiceId}
                            onChange={(voice_id) => {
                                const newContents = {...params}
                                newContents.voiceId = voice_id ?? ""
                                setContents(newContents)
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="file name" value={params.fileName ?? ""} onChange={
                            (event) => {
                                const newContents = {...params}
                                newContents.fileName = event.target.value
                                setContents(newContents)
                            }
                        }/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="text" value={params.text} multiline={true} sx={{minWidth: 600}} onChange={
                            (event) => {
                                const newContents = {...params}
                                newContents.text = event.target.value
                                setContents(newContents)
                            }
                        }/>
                    </Grid>
                    <Grid item>
                        <Button sx={{minWidth: 120}} onClick={playSpeech}>Generate</Button>
                    </Grid>
                    {audioSrc && (
                        <Grid item>
                            <audio controls src={audioSrc}/>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <LinkDialogue ref={linkDialogueRef}/>
        </>
    )
}
