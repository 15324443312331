import {InputWidget} from "../../../models/contents.ts";
import {Grid, TextField, Typography,} from "@mui/material";

export function InputWidgetFormEqAnswer(props: {
    label: string
    value: InputWidget.EqAnswer
    onChange: (value: InputWidget.EqAnswer) => void
}) {
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                    <Typography>
                        {props.label}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="answer"
                        value={props.value.answer}
                        onChange={(event) => {
                            props.onChange({...props.value, answer: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="answer_name"
                        value={props.value.answer_name || ""}
                        onChange={(event) => {
                            props.onChange({...props.value, answer_name: event.target.value})
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
