import {Autocomplete, TextField} from "@mui/material";
import {useState} from "react";

export default (props: {
    value: string[],
    onChange: (value: string[]) => void,
}) => {
    const [history, setHistory] = useState(() => {
        const _history = localStorage.getItem("knowledgeTags")
        if (_history !== null) {
            return _history.split(",")
        }
        return []
    });
    const addHistory = (h: string[]) => {
        const currentHistory = localStorage.getItem("knowledgeTags")?.split(",") || [];
        const newHistory = new Set(currentHistory)
        for (const v of h) {
            newHistory.add(v)
        }
        if (newHistory.size !== currentHistory.length) {
            localStorage.setItem("knowledgeTags", [...newHistory].join(","))
            setHistory([...newHistory])
        }
    }
    return (
        <Autocomplete
            multiple
            freeSolo
            options={history}
            value={props.value}
            onChange={(_, newValue) => {
                props.onChange(newValue)
                addHistory(newValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Knowledge Tags(Press Enter to add)"
                    placeholder="Knowledge Tags(Press Enter to add)"
                />
            )}
        />
    )
}
