import {VideoWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";
import React from "react";

function VideoWidgetForm(props: {
    value: VideoWidget
    onChange: (value: Widget) => void
}) {
    if (props.value.form === undefined) {
        props.value.form = {
            url: props.value.parameters?.url || ""
        }
        props.onChange(props.value)
        return <></>
    }
    const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = {...props.value}
        newValue.form =
            Object.assign({}, {
                url: event.target.value
            })
        props.onChange(newValue)
    }
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    defaultValue={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    fullWidth
                    label="url"
                    defaultValue={props.value.form?.url}
                    onChange={_onChange}
                />
            </Grid>
        </>
    );
}

export default VideoWidgetForm;
