import {useState} from "react";
import {httpClient} from "../../util/axios-helper.ts";
import CommonAppBar from "../misc/common-app-bar.tsx";
import {Questions} from "../../models/Questions.ts";

function QuestionsAppBar(props: {
    load: (questions: Questions) => void,
    clear: () => void,
    normalizeQuestions: () => Questions,
}) {
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
    const [normalizedQuestions, setNormalizedQuestions] = useState<Questions | undefined>(undefined)

    const exportQuestions = () => {
        const questions = props.normalizeQuestions()
        const blob = new Blob([JSON.stringify(questions, null, 2)], {type: "application/json"})
        const url = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `${questions.baseKnowledgeTagId}.json`
        a.click()
    }

    const previewQuestions = async () => {
        const questions = props.normalizeQuestions()
        const blob = new Blob([JSON.stringify(questions, null, 2)], {type: "application/json"})
        const previewPath = `pv/q/${Math.floor(new Date().getTime() / 1000)}_${questions.baseKnowledgeTagId}.json`
        await httpClient.postForm(
            "/api/v1/upload_file",
            {
                file: blob,
                filePath: previewPath,
            }
        )
        setPreviewUrl(`${import.meta.env.VITE_BASE_LESSON_URL}/${previewPath}`)
        setNormalizedQuestions(questions)
    }

    return (
        <CommonAppBar
            title="Questions Generator"
            color="secondary"
            load={props.load}
            clear={props.clear}
            exportContents={exportQuestions}
            previewContents={previewQuestions}
            previewUrl={previewUrl}
            data={normalizedQuestions}
        />
    )
}

export default QuestionsAppBar;
