import {ChoiceWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";

function ChoiceWidgetForm(props: {
    value: ChoiceWidget
    onChange: (value: Widget) => void
}) {
    if (!props.value.parameters) {
        props.onChange({
            ...props.value,
            parameters: {
                image_urls: [] as string[],
                answer: 0,
            },
            form: {
                image_urls: [] as string[],
                answer: 0,
            },
        } as ChoiceWidget)
        return <></>
    }
    if (!props.value.form) {
        props.onChange({
            ...props.value,
            form: {
                image_urls: [] as string[],
                answer: 0,
            },
        } as ChoiceWidget)
        return <></>
    }
    const form = props.value.form!

    const setForm = (form: { image_urls: string[], answer: number }) => {
        const newValue = {...props.value}
        newValue.form = form
        props.onChange(newValue)
    }

    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    value={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="answer"
                    value={form?.answer}
                    onChange={(event) => {
                        setForm({...form, answer: Number(event.target.value)})
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    label="image_urls"
                    helperText="改行で複数のURLを指定できます"
                    value={form?.image_urls.join("\n")}
                    onChange={(event) => {
                        setForm({
                            ...form,
                            image_urls: event.target.value.split("\n")
                        })
                    }}
                />
            </Grid>

        </>
    );
}

export default ChoiceWidgetForm;
