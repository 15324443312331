import {Align, FormHintExplanation, HandWritingWidget, KeyBordType, Widget,} from "../../models/contents.ts"
import {Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import {InputFromImage} from "../misc/input-from-image.tsx"
import React, {useState} from "react"
import HintExplanationForm from "./input-widget-form/hint-explanation-form.tsx"
import {QuestionsOption} from "../misc/questions-option.tsx";
import { VoiceIdInput } from "../misc/voice-id-input.tsx"
import {HintExplanationFormCopyPastePanel} from "./input-widget-form/hint-explanation-form-copy-paste-panel.tsx";

function HandWritingWidgetForm(props: {
    value: HandWritingWidget,
    onChange: (value: Widget) => void,
    isQuestion: boolean,
}) {
  const form = props.value.form
  if (form === undefined) {
    props.value.form = {
      image_url: "",
      answer: "",
      type: "general",
      note_h: 0,
      align: "center",
      original: {
        w: 0,
        h: 0,
      },
      display: {
        w: 0,
      },
    }
    props.onChange(props.value)
    return <></>
  }

  const setForm = (value: HandWritingWidget.Form) => {
    const newValue = { ...props.value }
    newValue.form = value
    props.onChange(newValue)
  }

  type TabKey = "base" | "hint"
  const [tabKey, setTabKey] = useState<TabKey>("base")
  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabKey) => {
    setTabKey(newValue)
  }

  return (
    <>
      <Grid item xs={3}>
        <TextField fullWidth label="type" value={props.value.type} disabled={true} />
      </Grid>
      {props.isQuestion ? (
            <QuestionsOption value={props.value} onChange={props.onChange}/>
        ) : (
            <></>
        )}
      <Grid item xs={props.isQuestion ? 4 : 9}>
        <InputFromImage
          onExtract={(value) => {
            const newValue = { ...props.value }
            newValue.form!.original.w = value.w
            newValue.form!.original.h = value.h
            newValue.form!.image_url = value.fileName
            props.onChange(newValue)
          }}
        />
      </Grid>
      <TabContext value={tabKey}>
        <Grid item xs={12}>
          <TabList onChange={handleTabChange}>
            <Tab label="Base" value="base" />
            <Tab label="Hint / Explanation" value="hint" />
          </TabList>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value="base">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="url"
                  value={form.image_url}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({
                      ...form,
                      image_url: event.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="original image width"
                  type="number"
                  value={form.original.w}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    const newValue = { ...props.value }
                    newValue.form!.original.w = Number(event.target.value)
                    props.onChange(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="original image height"
                  type="number"
                  value={form.original.h}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    const newValue = { ...props.value }
                    newValue.form!.original.h = Number(event.target.value)
                    props.onChange(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="width when displayed"
                  type="number"
                  value={form.display.w}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    const newValue = { ...props.value }
                    newValue.form!.display.w = Number(event.target.value)
                    props.onChange(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id={`align-${props.value.id}`}>Align</InputLabel>
                  <Select
                    labelId={`align-${props.value.id}`}
                    label="Align"
                    onChange={(event) => {
                      setForm({
                        ...form,
                        align: event.target.value as Align,
                      })
                    }}
                    defaultValue={form.align}
                  >
                    <MenuItem value="left">left</MenuItem>
                    <MenuItem value="center">center</MenuItem>
                    <MenuItem value="right">right</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="note height"
                  type="number"
                  value={form.note_h}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value) < 0) {
                      return
                    }
                    setForm({
                      ...form,
                      note_h: Number(event.target.value),
                    })
                  }}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="answer"
                  value={form.answer}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({
                      ...form,
                      answer: event.target.value,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id={`type-${props.value.id}`}>Type</InputLabel>
                  <Select
                    labelId={`type-${props.value.id}`}
                    label="Type"
                    onChange={(event) => {
                      setForm({
                        ...form,
                        type: event.target.value as KeyBordType,
                      })
                    }}
                    defaultValue={form.type}
                  >
                    <MenuItem value="general">general</MenuItem>
                    <MenuItem value="number">number</MenuItem>
                    <MenuItem value="symbol">symbol</MenuItem>
                    <MenuItem value="full">full</MenuItem>
                    <MenuItem value="fraction">fraction</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <VoiceIdInput
                  id={props.value.id}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      voice_id: value,
                    })
                  }}
                  value={props.value?.form?.voice_id ?? ""}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="text"
                  value={form.text ?? ""}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      text: event.target.value ? event.target.value : undefined,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.is_markdown ?? false}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          is_markdown: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="is markdown"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.no_icon ?? false}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          no_icon: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="no icon"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="hint">
            <Stack spacing={2}>
              <HintExplanationForm
                widgetId={`hint-${props.value.id}`}
                title="Hint"
                value={form.hint}
                onChange={(value: FormHintExplanation) => {
                  setForm({
                    ...form,
                    hint: value,
                  })
                }}
              />
              <HintExplanationForm
                widgetId={`explanation-${props.value.id}`}
                title="Explanation"
                value={form.explanation}
                onChange={(value: FormHintExplanation) => {
                  setForm({
                    ...form,
                    explanation: value,
                  })
                }}
              />
              <HintExplanationFormCopyPastePanel
                hint={form.hint}
                explanation={form.explanation}
                onPaste={(hint, explanation) => {
                  setForm({
                    ...form,
                    hint: hint,
                    explanation: explanation,
                  })
                }}
              />
            </Stack>
          </TabPanel>
        </Grid>
      </TabContext>
    </>
  )
}

export default HandWritingWidgetForm
