import {Contents} from "../models/contents.ts";
import {Questions} from "../models/Questions.ts";

export const isContents = (arg: any): arg is Contents => {
    return arg.grade !== undefined
}

export const isQuestions = (arg: any): arg is Questions => {
    return arg.baseKnowledgeTagId !== undefined
}

const normalizeUrlByContents = (url: string, contents: Contents) => {
    let versionSuffix = ""
    if (contents.lessonVersion && contents.lessonVersion > 1) {
        versionSuffix = `_${contents.lessonVersion}`
    }
    if(contents.lessonId){
        return `${import.meta.env.VITE_BASE_CONTENTS_URL}/l/${contents.lessonId}/${contents.lessonVersion}/${url}`
    }
    return `${import.meta.env.VITE_BASE_CONTENTS_URL}/${contents.grade}/${
        String(contents.unit).padStart(2, "0")
    }/${
        String(contents.lesson).padStart(2, "0")
    }${versionSuffix}/${url}`
}

const normalizeUrlByQuestions = (url: string, questions: Questions) => {
    if (!questions.baseKnowledgeTagId.startsWith("U")) {
        return `${import.meta.env.VITE_BASE_CONTENTS_URL}/${questions.baseKnowledgeTagId}/${url}`
    }
    const level1 = questions.baseKnowledgeTagId.substring(0, 3);
    if (questions.baseKnowledgeTagId.length == 3) {
        return `${import.meta.env.VITE_BASE_CONTENTS_URL}/q/${level1}/${url}`
    }
    const level2 = questions.baseKnowledgeTagId.substring(0, 5);
    if (questions.baseKnowledgeTagId.length == 5) {
        return `${import.meta.env.VITE_BASE_CONTENTS_URL}/q/${level1}/${level2}/${url}`
    }
    const level3 = questions.baseKnowledgeTagId.substring(0, 7);
    if (questions.baseKnowledgeTagId.length == 7) {
        return `${import.meta.env.VITE_BASE_CONTENTS_URL}/q/${level1}/${level2}/${level3}/${url}`
    }
    const level4 = questions.baseKnowledgeTagId;
    return `${import.meta.env.VITE_BASE_CONTENTS_URL}/q/${level1}/${level2}/${level3}/${level4}/${url}`
}

export const UrlHelper = {
    normalizeUrl: (url: string | undefined, contents: Contents | Questions) => {
        if (!url) {
            return ""
        }
        if (url.startsWith("http://") || url.startsWith("https://")) {
            return url
        }
        if (url.startsWith("/")) {
            return `${import.meta.env.VITE_BASE_CONTENTS_URL}${url}`
        }
        if (isContents(contents)) {
            return normalizeUrlByContents(url, contents)
        }
        if (isQuestions(contents)) {
            return normalizeUrlByQuestions(url, contents)
        }
        throw new Error("invalid contents")
    }
}
