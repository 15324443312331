import {LessonsGenerator} from "./components/lessons-generator/lessons-generator.tsx";
import {SynthesizeSpeech} from "./components/misc/synthesize_speech.tsx";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {QuestionsGenerator} from "./components/questions-generator/questions-generator.tsx";

export const PATHS = {
    LESSONS_GENERATOR: "/",
    SYNTHESIZE_SPEECH: "/synthesize-speech",
    QUESTIONS_GENERATOR: "/questions-generator",
}

const router = createBrowserRouter([
    {path: PATHS.LESSONS_GENERATOR, element: <LessonsGenerator/>},
    {path: PATHS.QUESTIONS_GENERATOR, element: <QuestionsGenerator/>},
    {path: PATHS.SYNTHESIZE_SPEECH, element: <SynthesizeSpeech/>},
])

function App() {
    return (
        <RouterProvider router={router}/>
    )
}

export default App
