import {DialogueWidget, IconPosition, Widget} from "../../models/contents"
import {
    Autocomplete,
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {VoiceIdInput} from "../misc/voice-id-input.tsx";
import { Thumbnail } from "../misc/thumbnail.tsx";
import { VoiceId } from "../../models/voice-id.ts";

function DialogueWidgetForm(props: {
    value: DialogueWidget
    onChange: (value: Widget) => void
}) {
    const form = props.value.form
    if (form === undefined) {
        const newValue = {
                ...props.value,
                form: Object.assign({
                    icon_url: "",
                    icon_position: "left" as IconPosition,
                    bg_color: "",
                    text: "",
                    voice_text: "",
                    audio_url: ""
                }, props.value.parameters)
            }
            props.onChange(newValue)
        return <></>
    }


    const setForm = (form: DialogueWidget["form"]) => {
        const newValue = {...props.value}
        newValue.form = form
        props.onChange(newValue)
    }

    enum BgColor {
        yellow = '#FDF1BE',
        pink = '#F3D4C6',
        purple = '#DFD2E4',
        green = '#E2EED8',
        blue = '#D2DFF0',
    }
    const iconDefinition: { [iconFileName: string]: { voiceId: VoiceId, bgColor: BgColor } } = {
        'KUMA-01.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-02.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-03.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-04.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-05.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-06.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-07.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-08.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-09.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-10.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-11.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-12.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-13.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-14.webp': { voiceId: VoiceId.pop1, bgColor: BgColor.yellow },
        'KUMA-15.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-16.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-17.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-18.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-19.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-20.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-21.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-22.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-23.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-24.webp': { voiceId: VoiceId.pop1, bgColor: BgColor.yellow },
        'KUMA-25.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-26.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-27.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-28.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-29.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-30.webp': { voiceId: VoiceId.teacher, bgColor: BgColor.pink },
        'KUMA-31.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-32.webp': { voiceId: VoiceId.pop1, bgColor: BgColor.yellow },
        'KUMA-33.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.blue },
        'KUMA-34.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-35.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-36.webp': { voiceId: VoiceId.pop3, bgColor: BgColor.yellow },
        'KUMA-37.webp': { voiceId: VoiceId.pop4, bgColor: BgColor.purple },
        'KUMA-38.webp': { voiceId: VoiceId.pop2, bgColor: BgColor.green },
        'KUMA-41.webp': { voiceId: VoiceId.teacher, bgColor: BgColor.pink },
        'KUMA-43.webp': { voiceId: VoiceId.teacher, bgColor: BgColor.pink },
        'KUMA-44.webp': { voiceId: VoiceId.teacher, bgColor: BgColor.pink },
        'KUMA-45.webp': { voiceId: VoiceId.teacher, bgColor: BgColor.pink },
    }

    // set icon_url and autofill voice_id and bg_color
    const setIconUrl = (iconUrl: string) => {
        const d = iconDefinition[iconUrl.replace(/.+\//, '')]

        setForm({
            ...form,
            icon_url: iconUrl,
            voice_id: d?.voiceId ?? form.voice_id,
            bg_color: d?.bgColor ?? form.bg_color,
        })
    }

    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    value={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel control={
                    <Checkbox
                        checked={form.version === 2 ?? false}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                version: event.target.checked ? 2 : undefined,
                            })
                        }}/>
                } label="v2"/>
            </Grid>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    value={form.icon_url}
                    freeSolo
                    disableClearable
                    options={
                        Object.keys(iconDefinition).map((key) =>
                            `https://contents.dev-3f5.mathmaji.com/common/${key}`
                        )
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label="icon_url"
                            onChange={(event) => {
                                setIconUrl(event.target.value)
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        setIconUrl(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                {props.value.parameters?.icon_url && <Thumbnail url={props.value.parameters?.icon_url}/>}
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel id={`icon_position-${props.value.id}`}>icon_position</InputLabel>
                    <Select
                        labelId={`icon_position-${props.value.id}`}
                        label="icon_position"
                        onChange={(event) => {
                            const v = event.target.value as string
                            const newValue = {...props.value}
                            newValue.form!.icon_position = v as IconPosition
                            props.onChange(newValue)
                        }}
                        defaultValue={form.icon_position}
                    >
                        <MenuItem value="left">left</MenuItem>
                        <MenuItem value="right">right</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Autocomplete
                    value={form.bg_color}
                    freeSolo
                    disableClearable
                    options={Object.values(BgColor)}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <Box style={{
                                width: "13px",
                                height: "13px",
                                marginRight: "4px",
                                border: "1px solid #616061",
                                borderRadius: "3px",
                                backgroundColor: option
                            }} />
                            {option}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label="bg_color"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <Box style={{
                                        width: "13px",
                                        height: "13px",
                                        border: "1px solid #616061",
                                        borderRadius: "3px",
                                        backgroundColor: props.value.form?.bg_color
                                    }} />
                                ),
                            }}
                            onChange={(event) => {
                                setForm({...form, bg_color: event.target.value})
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        setForm({...form, bg_color: newValue})
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <VoiceIdInput
                    id={props.value.id}
                    value={props.value.form?.voice_id ?? ""}
                    onChange={(voice_id) => {
                        setForm({...form, voice_id})
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel control={
                    <Checkbox
                        checked={form.is_markdown ?? false}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                is_markdown: event.target.checked
                            })
                        }}/>
                } label="is markdown"/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="text"
                    value={props.value.form?.text}
                    onChange={(event) => {
                        setForm({...form, text: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="voice text"
                    value={props.value.form?.voice_text}
                    onChange={(event) => {
                        setForm({...form, voice_text: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="audio url"
                    value={props.value.form?.audio_url ?? ""}
                    onChange={(event) => {
                        setForm({...form, audio_url: event.target.value})
                    }}
                />
            </Grid>
        </>
    );
}
export default DialogueWidgetForm;
