import {useContext} from "react";
import {ContentContext} from "../lessons-generator/lessons-generator.tsx";
import {UrlHelper} from "../../util/url-helper.ts";


export function Thumbnail(props: {
    url: string
}) {
    const content = useContext(ContentContext)
    const imgSrc = UrlHelper.normalizeUrl(props.url, content)
    return <img src={imgSrc} alt="thumbnail" style={{
            "maxHeight": "56px",
            "width": "100%",
            "objectFit": "contain",
        }}/>

}
