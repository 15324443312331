import {Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, TextField} from "@mui/material";
import {
    FormHintExplanation,
    HandWritingWidget,
    HandWritingV2Widget,
    InputWidget,
    MultipleChoiceWidget,
    Widget,
    WidgetType
} from "../../models/contents.ts";
import {useContext, useState} from "react";
import {ExplorerContext} from "../../context/explorer.tsx";
import {cloneDeep} from "lodash";
import {generateWidgetId} from "../misc/shuffle-id.tsx";
import {normalizeWidget} from "../../common/widget.ts";
import {QuestionsWidget} from "../../models/Questions.ts";

export function WidgetConverter(props: {
    getSelectedWidgets: () => Widget[],
    deleteWidgets: () => void,
}) {
    const [showDialog, setShowDialog] = useState(false);
    const [baseKnowledgeTagId, setBaseKnowledgeTagId] = useState<string>("");
    const [additionalKnowledgeTagIds, setAdditionalKnowledgeTagIds] = useState<string[]>([]);
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
    const {
        isStarted,
        getQuestions,
        moveFile,
        saveContents,
    } = useContext(ExplorerContext);

    const convertUrl = (url: string, id: string): string => {
        if (url.startsWith("http://") || url.startsWith("https://")) {
            return url
        }
        return url.replace(/(\.[^.]+)$/, '_' + id + '$1')
    }

    const convertFormHintExplanation = (form: FormHintExplanation | undefined, id: string): FormHintExplanation | undefined => {
        if (!form) {
            return undefined
        }
        return {
            ...form,
            url: convertUrl(form.url, id)
        }
    }

    const convert = async () => {
        const widgets = props.getSelectedWidgets()
        if (widgets.length === 0) {
            alert("No widgets selected")
            return
        }
        let questions;
        try {
            questions = await getQuestions(baseKnowledgeTagId)
        } catch (e) {
            alert("need baseKnowledgeTagId json")
            return
        }
        try {
            const changedImage: {
                before: string | undefined
                after: string | undefined
            }[] = []
            const newWidgets = widgets.map((widget) => {
                let newWidget = cloneDeep(widget)
                newWidget.id = generateWidgetId()
                newWidget.knowledgeTagIds = additionalKnowledgeTagIds
                switch (newWidget.type) {
                    case WidgetType.Input:
                        const inputWidget = widget as InputWidget
                        let newInputWidget = newWidget as InputWidget
                        newInputWidget.form = {
                            ...inputWidget.form!!,
                            explanation: convertFormHintExplanation(inputWidget.form?.explanation, newWidget.id),
                            hint: convertFormHintExplanation(inputWidget.form?.hint, newWidget.id),
                        }
                        switch (newInputWidget.form?.template) {
                            case null:
                            case undefined:
                            case "Manual":
                                if (newInputWidget.form.set_image_url) {
                                    newInputWidget.form.image_url = convertUrl(newInputWidget.form.image_url!!, newWidget.id)
                                } else {
                                    newInputWidget.form.set_image_url = true
                                    newInputWidget.form.image_url = convertUrl(
                                        `q.${newInputWidget.form?.extension}`,
                                        newWidget.id
                                    )
                                }
                                break;
                            case "EqFull":
                            case "EqPartial":
                                break;
                        }
                        newInputWidget = normalizeWidget(newInputWidget, questions) as InputWidget

                        switch (newInputWidget.form?.template) {
                            case null:
                            case undefined:
                            case "Manual":
                                changedImage.push({
                                    before: inputWidget.parameters?.image_url,
                                    after: newInputWidget.parameters?.image_url,
                                })
                                break;
                            case "EqFull":
                            case "EqPartial":
                                break;
                        }
                        changedImage.push({
                            before: inputWidget.parameters?.hint?.url,
                            after: newInputWidget.parameters?.hint?.url,
                        })
                        changedImage.push({
                            before: inputWidget.parameters?.explanation?.url,
                            after: newInputWidget.parameters?.explanation?.url,
                        })
                        newWidget = newInputWidget
                        break;
                    case WidgetType.MultipleChoice:
                        const multipleChoiceWidget: MultipleChoiceWidget = widget as MultipleChoiceWidget
                        let newMultipleChoiceWidget: MultipleChoiceWidget = newWidget as MultipleChoiceWidget
                        newMultipleChoiceWidget.form = {
                            ...multipleChoiceWidget.form!!,
                            image_url: convertUrl(multipleChoiceWidget.form?.image_url!!, newWidget.id),
                            explanation: convertFormHintExplanation(multipleChoiceWidget.form?.explanation, newWidget.id),
                            hint: convertFormHintExplanation(multipleChoiceWidget.form?.hint, newWidget.id),
                            images: multipleChoiceWidget.form?.images?.map((image) => {
                                return {
                                    ...image,
                                    url_default: convertUrl(image.url_default, newWidget.id),
                                    url_selected: convertUrl(image.url_selected, newWidget.id),
                                }
                            }) ?? [],
                        }
                        newMultipleChoiceWidget = normalizeWidget(newMultipleChoiceWidget, questions) as MultipleChoiceWidget
                        changedImage.push({
                            before: multipleChoiceWidget.parameters?.image.url,
                            after: newMultipleChoiceWidget.parameters?.image.url,
                        })
                        changedImage.push({
                            before: multipleChoiceWidget.parameters?.hint?.url,
                            after: newMultipleChoiceWidget.parameters?.hint?.url,
                        })
                        changedImage.push({
                            before: multipleChoiceWidget.parameters?.explanation?.url,
                            after: newMultipleChoiceWidget.parameters?.explanation?.url,
                        });
                        (multipleChoiceWidget.parameters as MultipleChoiceWidget.Parameters | undefined)?.images?.forEach((image, index) => {
                            changedImage.push({
                                before: image.url_default,
                                after: newMultipleChoiceWidget.parameters.images[index].url_default,
                            })
                            changedImage.push({
                                before: image.url_selected,
                                after: newMultipleChoiceWidget.parameters.images[index].url_selected,
                            })
                        })
                        newWidget = newMultipleChoiceWidget
                        break;
                    case WidgetType.HandWriting:
                        const handWritingWidget = widget as HandWritingWidget
                        let newHandWritingWidget = newWidget as HandWritingWidget
                        newHandWritingWidget.form = {
                            ...handWritingWidget.form!!,
                            explanation: convertFormHintExplanation(handWritingWidget.form?.explanation, newWidget.id),
                            hint: convertFormHintExplanation(handWritingWidget.form?.hint, newWidget.id),
                            image_url: convertUrl(handWritingWidget.form?.image_url!!, newWidget.id),
                        }
                        newHandWritingWidget = normalizeWidget(newHandWritingWidget, questions) as HandWritingWidget
                        changedImage.push({
                            before: handWritingWidget?.parameters?.image.url,
                            after: newHandWritingWidget?.parameters?.image.url,
                        })
                        changedImage.push({
                            before: handWritingWidget?.parameters?.hint.url,
                            after: newHandWritingWidget?.parameters?.hint.url,
                        })
                        changedImage.push({
                            before: handWritingWidget?.parameters?.explanation.url,
                            after: newHandWritingWidget?.parameters?.explanation.url,
                        })
                        newWidget = newHandWritingWidget
                        break;
                    case WidgetType.HandWritingV2:
                        const handWritingV2Widget = widget as HandWritingV2Widget
                        let newHandWritingV2Widget = newWidget as HandWritingV2Widget
                        handWritingV2Widget.form = {
                            ...handWritingV2Widget.form!!,
                            explanation: convertFormHintExplanation(handWritingV2Widget.form?.explanation, newWidget.id),
                            hint: convertFormHintExplanation(handWritingV2Widget.form?.hint, newWidget.id),
                            image_url: convertUrl(handWritingV2Widget.form?.image_url!!, newWidget.id),
                        }
                        newHandWritingWidget = normalizeWidget(handWritingV2Widget, questions) as HandWritingWidget
                        changedImage.push({
                            before: handWritingV2Widget?.parameters?.image.url,
                            after: handWritingV2Widget?.parameters?.image.url,
                        })
                        changedImage.push({
                            before: handWritingV2Widget?.parameters?.hint.url,
                            after: newHandWritingV2Widget?.parameters?.hint.url,
                        })
                        changedImage.push({
                            before: handWritingV2Widget?.parameters?.explanation.url,
                            after: newHandWritingV2Widget?.parameters?.explanation.url,
                        })
                        newWidget = newHandWritingV2Widget
                        break
                    default:
                        return undefined;
                }
                console.log(newWidget);
                return newWidget
            }).filter((widget) => widget !== undefined) as QuestionsWidget[]

            changedImage.forEach((image) => {
                console.log(image);
                if (!image.before || !image.after) {
                    return
                }
                if (image.before === image.after) {
                    return
                }

                moveFile(
                    image.before.replace("https://contents.dev-3f5.mathmaji.com/", "/"),
                    image.after.replace("https://contents.dev-3f5.mathmaji.com/", "/"),
                    isDeleteImage
                )
            })

            questions.widgets.push(...newWidgets)
            await saveContents(questions)

            props.deleteWidgets()
        } catch (e) {
            alert("error")
            throw e
        }
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    if (!isStarted) {
                        alert("Explorer not started")
                        return
                    }
                    setShowDialog(true)
                }}
                sx={{my: 2, color: "white", display: "block"}}
            >Convert
            </Button>
            <Dialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                }}
            >
                <DialogTitle>Convert Widget</DialogTitle>
                <DialogContent>
                    <TextField label="baseKnowledgeTagId" value={baseKnowledgeTagId}
                               onChange={(e) => setBaseKnowledgeTagId(e.target.value)}/><br/>
                    <br/>
                    <TextField label="additionalKnowledgeTagIds" value={additionalKnowledgeTagIds.join(",")}
                               onChange={(e) => setAdditionalKnowledgeTagIds(e.target.value.split(","))}/><br/>
                    comma separate.<br/>
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isDeleteImage}
                                onChange={(e) => setIsDeleteImage(e.target.checked)}
                            />
                        }
                        label="delete image"
                    /><br/>
                    <Button onClick={() => {
                        setShowDialog(false)
                        convert()
                    }}>convert</Button>
                </DialogContent>
            </Dialog>
        </>
    )
}
