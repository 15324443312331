import {Button, CardActions} from "@mui/material";
import {ContentPaste, FileCopy} from "@mui/icons-material";
import {FormHintExplanation} from "../../../models/contents.ts";

export function HintExplanationFormCopyPastePanel(props: {
  hint?: FormHintExplanation,
  explanation?: FormHintExplanation,
  onPaste: (hint: FormHintExplanation, explanation: FormHintExplanation) => void,
}) {
  return (
    <CardActions>
      <Button size="small" color="primary" onClick={() => {
        const hintExplanation = {
          hint: props.hint,
          explanation: props.explanation,
        }
        localStorage.setItem("copied-hintExplanation", JSON.stringify(hintExplanation))
      }}><FileCopy /></Button>
      <Button size="small" color="primary" onClick={() => {
        const copiedHintExplanation = localStorage.getItem("copied-hintExplanation")
        if (copiedHintExplanation == null) {
          return
        }
        const hintExplanation = JSON.parse(copiedHintExplanation)
        props.onPaste(hintExplanation.hint, hintExplanation.explanation)
      }}><ContentPaste /></Button>
    </CardActions>
  );
}
