export enum VoiceId {
    teacher = 'teacher',
    teacher_s = 'teacher_s',
    pop1 = 'pop1',
    pop1_s = 'pop1_s',
    pop2 = 'pop2',
    pop3 = 'pop3',
    pop3_s = 'pop3_s',
    pop4 = 'pop4',
    friend1 = 'friend1',
    friend1_s = 'friend1_s',
    friend2 = 'friend2',
    friend2_s = 'friend2_s',
    friend3 = 'friend3',
    friend3_s = 'friend3_s',
    friend4 = 'friend4',
    friend4_s = 'friend4_s',
}

export const VoiceIdList: VoiceId[] = [
    VoiceId.teacher, VoiceId.teacher_s, VoiceId.pop1, VoiceId.pop1_s, VoiceId.pop2, VoiceId.pop3, VoiceId.pop3_s, VoiceId.pop4, VoiceId.friend1, VoiceId.friend1_s, VoiceId.friend2, VoiceId.friend2_s, VoiceId.friend3, VoiceId.friend3_s, VoiceId.friend4, VoiceId.friend4_s,
]
