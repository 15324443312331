import {Button} from "@mui/material";
import {Questions} from "../../models/Questions.ts";
import {Contents} from "../../models/contents.ts";

export function generateWidgetId() {
    return Math.random().toString(32).substring(2)
}


export function ShuffleId(props: {
    contents: Contents | Questions,
    setContents: (value: Contents | Questions) => void,
}) {
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    const newContents = {...props.contents}
                    newContents.widgets.map((widget) => {
                        widget.id = generateWidgetId()
                    })
                    props.setContents(newContents)
                }}
                sx={{my: 2, color: "white", display: "block"}}
            >Shuffle ID
            </Button>
        </>
    )
}
