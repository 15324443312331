
import {Button} from "@mui/material";

export default function BulkDelete(props: {
    deleteWidgets: () => void,
}) {
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    props.deleteWidgets()
                }}
                sx={{my: 2, color: "white", display: "block"}}
            >Bulk Delete
            </Button>
        </>
    )
}