import {DividerWidget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";

function DividerWidgetForm(props: {
    value: DividerWidget
}) {
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    defaultValue={props.value.type}
                    disabled={true}
                />
            </Grid>
        </>
    );
}

export default DividerWidgetForm;
