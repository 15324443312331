import {Box, Button, Drawer, IconButton, styled, Typography, useTheme} from "@mui/material";
import {SyntheticEvent, useContext, useState} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ExplorerContext} from "../../context/explorer.tsx";
import {RichTreeView} from "@mui/x-tree-view";
import Folder from "@mui/icons-material/Folder";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DescriptionIcon from '@mui/icons-material/Description';


export const ExplorerPanel = () => {
    const {
        open,
        isStarted,
        fileMap,
        fileTree,
        toggleExplorer,
        load,
        handleShowDirectoryPicker,
        saveFile,
        errorMessage
    } = useContext(ExplorerContext);

    const [selectedId, setSelected] = useState<string | null>(null);

    const theme = useTheme();

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      }));

    const handleItemSelectionToggle = (_event: SyntheticEvent, itemId: string, isSelected: boolean) => {
        if (isSelected) {
            setSelected(itemId);
        }
    };

    const handleLoadJson = () => {
        if (!selectedId) return;
        // Find the file with the specified id
        const fileData = fileMap[selectedId];
        if (!fileData.contents) return;
        load(fileData.contents);
    };

    const handleExportJson = () => {
        saveFile()
        .then(() => {
            console.log('File saved successfully.');
        })
        .catch((error) => {
            console.error('Error saving file:', error);
        });
    }

    return (
        <>
            <Drawer
                sx={{
                    width: 500,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 500,
                        boxSizing: 'border-box',
                    },
                    }}
                variant="persistent"
                open={open}
                onClose={toggleExplorer}
                anchor="right"
            >
                <DrawerHeader>
                    <IconButton onClick={toggleExplorer}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }} color="#00000"> File Explorer </Typography>
                </DrawerHeader>
                {errorMessage && (
                    <Typography sx={{ color: 'red', paddingLeft: '30px' }}>{errorMessage}</Typography>
                )}
                <Typography sx={{color: 'red', paddingLeft: '30px'}}>
                    {isStarted ? "" : "mathmaji-contents/publicを指定してください。"}
                </Typography>
                <Box sx={{paddingLeft: 2, paddingRight: 2}}>
                    <Box display="flex" justifyContent="space-evenly">
                        <Button
                            variant="contained"
                            onClick={handleShowDirectoryPicker}
                        >
                            {isStarted ? 'change root' : 'open'}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleLoadJson}
                        >
                            Load JSON
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleExportJson}
                        >
                            Export JSON
                        </Button>
                    </Box>
                </Box>
                <Box sx={{padding: 2, overflow: 'scroll'}}>                    
                    <Box height={4} />
                    <RichTreeView
                        items={fileTree.sort((a, b) => a.label.localeCompare(b.label))}
                        slots={{
                            expandIcon: Folder,
                            collapseIcon: FolderOpenIcon,
                            endIcon: DescriptionIcon,
                        }}
                        // when handleItemSelectionToggle fires, we set is selectedId to true or false depending on the event and itemId
                        onItemSelectionToggle={handleItemSelectionToggle}
                        // isItemDisabled={isItemDisabled}
                    />
                </Box>
            </Drawer>
        </>
    );
}
