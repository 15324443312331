import {InputWidget} from "../../../models/contents.ts";
import {Checkbox, FormControlLabel, Grid, TextField,} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import {InputWidgetFormEqAnswer} from "./input-widget-form-eq-answer.tsx";

export function InputWidgetFormEqPartial(props: {
    value: InputWidget.FormEqPartial
    onChange: (value: InputWidget.FormEqPartial) => void
}) {
    const form = props.value!
    const setForm = (value: InputWidget.FormEqPartial) => {
        props.onChange(value)
    }
    return (
        <TabPanel value="base">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label="text"
                        value={form.text}
                        onChange={(event) => {
                            setForm({...form, text: event.target.value})
                        }}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={form.is_markdown ?? false}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    is_markdown: event.target.checked
                                })
                            }}/>
                    } label="is markdown"/>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        multiline
                        fullWidth
                        label="voice text"
                        value={props.value.voice_text}
                        onChange={(event) => {
                            setForm({...form, voice_text: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={form.no_icon ?? false}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    no_icon: event.target.checked
                                })
                            }}/>
                    } label="no icon"/>
                </Grid>
                <Grid item xs={4}>
                    <InputWidgetFormEqAnswer label="answer 1" value={form.answer1} onChange={(value) => {
                        setForm({...form, answer1: value})
                    }}/>
                </Grid>
                <Grid item xs={4}>
                    <InputWidgetFormEqAnswer label="answer 2" value={form.answer2} onChange={(value) => {
                        setForm({...form, answer2: value})
                    }}/>
                </Grid>
                <Grid item xs={4}>
                    <InputWidgetFormEqAnswer label="answer 3" value={form.answer3} onChange={(value) => {
                        setForm({...form, answer3: value})
                    }}/>
                </Grid>
            </Grid>
        </TabPanel>
    );
}
