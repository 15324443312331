import {TextWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField, Checkbox, FormControlLabel} from "@mui/material";
import {VoiceIdInput} from "../misc/voice-id-input.tsx";

function TextWidgetForm(props: {
    value: TextWidget
    onChange: (value: Widget) => void
}) {
    const form = props.value.form
    if (form === undefined) {
        const newValue = {
            ...props.value,
            form: Object.assign({
                text: "",
                voice_text: "",
                audio_url: "",
                background_url: "",
                padding: 0,
            }, props.value.parameters)
        }
        props.onChange(newValue)
        return <></>
    }

    const setForm = (form: TextWidget["form"]) => {
        const newValue = {...props.value}
        newValue.form = form
        props.onChange(newValue)
    }
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    value={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={9}>
            </Grid>
            <Grid item xs={3}>
                <VoiceIdInput
                    id={props.value.id}
                    value={props.value.form?.voice_id ?? ""}
                    onChange={(voice_id) => {
                        setForm({...form, voice_id})
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel control={
                    <Checkbox
                        checked={form.is_markdown ?? false}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                is_markdown: event.target.checked
                            })
                        }}/>
                } label="is markdown"/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="text"
                    value={props.value.form?.text}
                    onChange={(event) => {
                        setForm({...form, text: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="voice text"
                    value={props.value.form?.voice_text}
                    onChange={(event) => {
                        setForm({...form, voice_text: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="audio url"
                    value={props.value.form?.audio_url ?? ""}
                    onChange={(event) => {
                        setForm({...form, audio_url: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="background url (not svg images)"
                    error={form.background_url?.endsWith(".svg")}
                    value={props.value.form?.background_url ?? ""}
                    onChange={(event) => {
                        setForm({...form, background_url: event.target.value})
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    type="number"
                    label="content padding"
                    value={props.value.form?.padding}
                    onChange={(event) => {
                        if (Number(event.target.value) < 0) {
                            return
                        }
                        setForm({...form, padding: Number(event.target.value)})
                    }}
                />
            </Grid>
        </>
    );
}
export default TextWidgetForm;
