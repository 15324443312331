export type Contents = {
    grade: string
    unit: number
    lesson: number
    lessonVersion?: number
    lessonId?: string
    widgets: Widget[]
}

export type Widget = {
    id: string
    type: WidgetType
    parameters?: any
    isOpen?: boolean // Questions only
    knowledgeTagIds?: string[] // Questions only
}

export enum WidgetType {
    Video = "video",
    Input = "input",
    Title = "title",
    Category = "category",
    Image = "image",
    Choice = "choice",
    Dialogue = "dialogue",
    MultipleChoice = "multiple_choice",
    HandWriting = "hand_writing",
    HandWritingV2 = "hand_writing_v2",
    Text = "text",
    Pause = "pause",
    Divider = "divider",
}

export type TitleWidget = {
    type: WidgetType.Title
    parameters?: {
        text: string
    }
} & Widget

export type CategoryWidget = {
    type: WidgetType.Category
    parameters?: {
        category: string
    }
} & Widget

export type VideoWidget = {
    type: WidgetType.Video
    parameters?: {
        url: string
    }
    form?: {
        url: string
    }
} & Widget

export type ImageWidget = {
    type: WidgetType.Image
    parameters?: {
        url: string
        w: number,
        h: number,
        text?: string,
        voice_id?: string,
    },
    form?: {
        url: string,
        original: {
            w: number,
            h: number,
        },
        display: {
            w: number,
        },
        text?: string,
        voice_id?: string,
    }
} & Widget

export type DialogueWidget = {
    type: WidgetType.Dialogue
    parameters?: {
        icon_url: string
        icon_position?: IconPosition
        bg_color: string
        text: string
        voice_text?: string
        audio_url?: string
        voice_id?: string
        is_markdown?: boolean
        version?: number
    }
    form?: DialogueWidget["parameters"]
} & Widget

export type TextWidget = {
    type: WidgetType.Text
    parameters?: {
        text: string
        voice_text?: string
        audio_url?: string
        voice_id?: string
        background_url?: string
        is_markdown?: boolean
        padding?: number
    }
    form?: TextWidget["parameters"]
} & Widget

export type InputWidget = {
    type: WidgetType.Input
    parameters?: InputWidget.Parameter
    form?: InputWidget.Form
} & Widget

export namespace InputWidget {
    export type Parameter = {
        image_url: string
        text?: string
        voice_id?: string
        voice_text?: string
        w: number
        h: number
        no_icon?: boolean
        text_boxes: TextBox[]
        additional_texts?: AdditionalText[]
        is_markdown?: boolean
        allow_reduction?: boolean
        hint?: HintExplanation
        explanation?: HintExplanation
        multiple_answers?: MultipleAnswer[]
    }

    export type TextBox = {
        x: number
        y: number
        h: number
        w: number
        font_size: number
        answer_name?: string
        type: KeyBordType
        answer: number | string
        max_characters?: number
        align?: "left" | "center" | "right"
    }

    export type MultipleAnswer = {
        answers: string[]
    }

    export type AdditionalText = {
        x: number
        y: number
        font_size: number
        text: string
        align: "left" | "center" | "right"
    }

    export type Form = FormManual | FormEqFull | FormEqPartial

    export type FormBase = {
        template?: "Manual" | "EqFull" | "EqPartial"
        hint?: FormHintExplanation
        explanation?: FormHintExplanation
    }

    export type FormManual = FormBase & {
        template?: "Manual"
        no: number
        extension: string
        text?: string,
        voice_id?: string,
        voice_text?: string
        original: {
            w: number
            h: number
        },
        display: {
            w: number
        }
        no_icon?: boolean
        input: FormTextBox[]
        is_markdown?: boolean
        allow_reduction?: boolean
        additional_texts?: AdditionalText[]
        set_image_url?: boolean
        image_url?: string
        is_multiple_answers: boolean
        multiple_answers?: string
    }

    export type FormEqFull = FormBase & {
        template: "EqFull"
        text?: string,
        no_icon?: boolean
        voice_text?: string
        answer1: EqAnswer
        answer2: EqAnswer
        answer3: EqAnswer
        answer4: EqAnswer
        answer5: EqAnswer
        is_markdown?: boolean
    }

    export type FormEqPartial = FormBase & {
        template: "EqPartial"
        text?: string,
        no_icon?: boolean
        voice_text?: string
        answer1: EqAnswer
        answer2: EqAnswer
        answer3: EqAnswer
        is_markdown?: boolean
    }

    export type EqAnswer = {
        answer: string
        answer_name?: string
    }

    export type FormTextBox = {
        x: number
        y: number
        relative_w: number
        relative_h: number
        answer: string
        font_size_ratio?: number
        type: KeyBordType
        max_characters?: number
        answer_name?: string
        align?: "left" | "center" | "right"
    }
}

export type ChoiceWidget = {
    type: WidgetType.Choice
    parameters?: {
        image_urls: string[]
        answer: number
    }
    form?: {
        image_urls: string[]
        answer: number
    }
} & Widget

export type MultipleChoiceWidget = {
    type: WidgetType.MultipleChoice
    parameters?: MultipleChoiceWidget.Parameters,
    form?: MultipleChoiceWidget.Form,
} & Widget

export namespace MultipleChoiceWidget {
    export type Parameters = {
        images: {
            url_default: string
            url_selected: string
            w: number
            h: number
        }[]
        answers: number[]
        direction: "horizontal" | "vertical"
        text?: string,
        image: {
            url: string
            w: number
            h: number
        } | undefined
        voice_id?: string
        voice_text?: string
        no_icon?: boolean
        is_markdown?: boolean
        hint?: HintExplanation
        explanation?: HintExplanation
    }

    export type Form = {
        images: FormImage[]
        direction: "horizontal" | "vertical"
        text?: string,
        voice_id?: string,
        voice_text?: string
        image_url?: string
        original: {
            w: number
            h: number
        } | undefined
        display: {
            w: number
        } | undefined
        no_icon?: boolean
        is_markdown?: boolean
        hint?: FormHintExplanation
        explanation?: FormHintExplanation
    }

    export type FormImage = {
        url_default: string
        url_selected: string
        is_answer: boolean
        original: {
            w: number
            h: number
        },
        display: {
            w: number
        }
    }
}

export type HandWritingWidget = {
    type: WidgetType.HandWriting
    parameters?: HandWritingWidget.Parameter
    form?: HandWritingWidget.Form
} & Widget

export namespace HandWritingWidget {
    export type Parameter = {
        image_url: string
        answer: string,
        max_characters?: number,
        type: KeyBordType,
        image_w: number
        image_h: number
        note_h: number
        align?: Align
        hint?: HintExplanation
        explanation?: HintExplanation
        text?: string
        voice_id?: string
        no_icon?: boolean
        is_markdown?: boolean
    }
    export type Form = {
        image_url: string
        answer: string,
        type: KeyBordType,
        original: {
            w: number
            h: number
        },
        display: {
            w: number
        },
        note_h: number
        align: Align
        hint?: FormHintExplanation
        explanation?: FormHintExplanation
        text?: string
        voice_id?: string
        no_icon?: boolean
        is_markdown?: boolean
    }
}

export type HandWritingV2Widget = {
    type: WidgetType.HandWritingV2
    parameters?: HandWritingV2Widget.Parameter
    form?: HandWritingV2Widget.Form
} & Widget

export namespace HandWritingV2Widget {
    export type Parameter = {
        inputs: TextBox[],
        image_url: string
        image_w: number
        image_h: number
        note_h: number
        align?: Align
        hint?: HintExplanation
        explanation?: HintExplanation
        text?: string
        voice_id?: string
        no_icon?: boolean
        is_markdown?: boolean
    }

    export type TextBox = {
        answer: string
        max_characters?: number,
        type: KeyBordType,
        suffix?: string
    }

    export type Form = {
        image_url: string
        inputs: FormTextBox[]
        original: {
            w: number
            h: number
        },
        display: {
            w: number
        },
        note_h: number
        align: Align
        hint?: FormHintExplanation
        explanation?: FormHintExplanation
        text?: string
        voice_id?: string
        no_icon?: boolean
        is_markdown?: boolean
    }

    export type FormTextBox = {
        answer: string
        type: KeyBordType,
        suffix?: string
    }
}

export type PauseWidget = {
    type: WidgetType.Pause
} & Widget

export type DividerWidget = {
    type: WidgetType.Divider
} & Widget

export type FormHintExplanation = {
    url: string,
    original: {
        w: number,
        h: number,
    },
    display: {
        w: number,
    },
    text?: string,
    voice_id?: string,
    is_markdown?: boolean,
    voice_text?: string,
};

export type HintExplanation = {
    text: string | undefined;
    image: {
        url: string;
        w: number;
        h: number;
    } | undefined;
    voice_id: string | undefined;
    is_markdown: boolean | undefined;
    voice_text: string | undefined;
};


export type KeyBordType = "number" | "symbol" | "general" | "fraction" | "full"
export type Align = "left" | "center" | "right"
export type IconPosition = "left" | "right"
