import {CategoryWidget, Widget} from "../../models/contents.ts";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";

function CategoryWidgetForm(props: {
    value: CategoryWidget
    onChange: (value: Widget) => void
}) {
    const category = ["try", "understand", "apply", "master"]
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    defaultValue={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id={`category-widget-${props.value.id}`}>Category</InputLabel>
                    <Select
                        labelId={`add-widget-${props.value.id}`}
                        label="category"
                        onChange={(event) => {
                            const newValue = {...props.value}
                            newValue.parameters = {
                                category: event.target.value as string
                            }
                            props.onChange(newValue)
                        }}
                        defaultValue={props.value.parameters?.category || ""}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {category.map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
}

export default CategoryWidgetForm;
