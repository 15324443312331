import {InputWidget} from "../../../models/contents.ts";
import {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function InputTextBoxForm(props: {
    widgetId: string
    idx: number,
    value: InputWidget.FormTextBox,
    isMultipleAnswerType?: boolean,
    onChange: (idx: number, value: InputWidget.FormTextBox) => void,
    onDelete: (idx: number) => void,
}) {
    const [open, setOpen] = useState(false)
    const form = props.value;

    const setForm = (form: InputWidget.FormTextBox) => {
        props.onChange(props.idx, form)
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="position x"
                        value={props.value.x}
                        onChange={(event) => {
                            setForm({...form, x: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="position y"
                        value={props.value.y}
                        onChange={(event) => {
                            setForm({...form, y: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="width relative to original"
                        value={props.value.relative_w}
                        onChange={(event) => {
                            setForm({...form, relative_w: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="number"
                        label="height relative to original"
                        value={props.value.relative_h}
                        onChange={(event) => {
                            setForm({...form, relative_h: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={() => setOpen(true)}>
                        <DeleteIcon/>
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id={`InputTextBoxForm-type-${props.widgetId}-${props.idx}`}>Category</InputLabel>
                        <Select
                            labelId={`InputTextBoxForm-type-${props.widgetId}-${props.idx}`}
                            label="answer type"
                            onChange={(event) => {
                                // @ts-ignore
                                setForm({...form, type: event.target.value})
                            }}
                            value={form.type}
                        >
                            <MenuItem value="general">
                                <em>general</em>
                            </MenuItem>
                            <MenuItem value="number">
                                <em>number</em>
                            </MenuItem>
                            <MenuItem value="fraction">
                                <em>fraction</em>
                            </MenuItem>
                            <MenuItem value="symbol">
                                <em>symbol</em>
                            </MenuItem>
                            <MenuItem value="full">
                                <em>full</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="answer"
                        disabled={props.isMultipleAnswerType === true}
                        value={props.value.answer}
                        onChange={(event) => {
                            setForm({...form, answer: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="font size ratio(%)"
                        value={props.value.font_size_ratio || ""}
                        onChange={(event) => {
                            const newValue = Number(event.target.value)
                            console.log(newValue)
                            if (!newValue || isNaN(newValue) || newValue < 1) {
                                setForm({...form, font_size_ratio: undefined})
                            }
                            setForm({...form, font_size_ratio: newValue})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="answer_name"
                        disabled={props.isMultipleAnswerType === true}
                        value={props.value.answer_name || ""}
                        onChange={(event) => {
                            const newValue = event.target.value
                            if (!newValue) {
                                setForm({...form, answer_name: undefined})
                            }
                            setForm({...form, answer_name: newValue})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        label="max_characters"
                        value={props.value.max_characters || ""}
                        onChange={(event) => {
                            const newValue = Number(event.target.value)
                            if (isNaN(newValue) || newValue < 1) {
                                setForm({...form, max_characters: undefined})
                            }
                            setForm({...form, max_characters: newValue})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id={`InputTextBoxForm-align-${props.widgetId}-${props.idx}`}>align</InputLabel>
                        <Select
                            labelId={`InputTextBoxForm-align-${props.widgetId}-${props.idx}`}
                            label="align"
                            onChange={(event) => {
                                // @ts-ignore
                                setForm({...form, align: event.target.value})
                            }}
                            value={form.align ?? "center"}
                        >
                            <MenuItem value="center">
                                <em>center</em>
                            </MenuItem>
                            <MenuItem value="left">
                                <em>left</em>
                            </MenuItem>
                            <MenuItem value="right">
                                <em>right</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete it</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        props.onDelete(props.idx)
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default InputTextBoxForm;
