import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {InputWidget} from "../../../models/contents.ts";
import {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";

function AdditinalTextForm(props: {
    widgetId: string
    idx: number,
    value: InputWidget.AdditionalText,
    onChange: (idx: number, value: InputWidget.AdditionalText) => void,
    onDelete: (idx: number) => void,
}) {
    const [open, setOpen] = useState(false)
    const form = props.value;

    const setForm = (form: InputWidget.AdditionalText) => {
        props.onChange(props.idx, form)
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="number"
                        label="position x"
                        value={props.value.x}
                        onChange={(event) => {
                            setForm({...form, x: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="number"
                        label="position y"
                        value={props.value.y}
                        onChange={(event) => {
                            setForm({...form, y: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="number"
                        value={props.value.font_size}
                        label="font size"
                        onChange={(event) => {
                            setForm({...form, font_size: Number(event.target.value)})
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        value={props.value.text}
                        label="text"
                        onChange={(event) => {
                            setForm({...form, text: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id={`AdditionalText-align-${props.widgetId}-${props.idx}`}>align</InputLabel>
                        <Select
                            labelId={`AdditionalText-align-${props.widgetId}-${props.idx}`}
                            label="align"
                            onChange={(event) => {
                                // @ts-ignore
                                setForm({...form, align: event.target.value})
                            }}
                            value={form.align ?? "center"}
                        >
                            <MenuItem value="center">
                                <em>center</em>
                            </MenuItem>
                            <MenuItem value="left">
                                <em>left</em>
                            </MenuItem>
                            <MenuItem value="right">
                                <em>right</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={() => setOpen(true)}>
                        <DeleteIcon/>
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete it</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        props.onDelete(props.idx)
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AdditinalTextForm;
