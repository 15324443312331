import {Card, CardContent, Grid, TextField, Typography, FormControlLabel, Checkbox} from "@mui/material"
import {VoiceIdInput} from "../../misc/voice-id-input.tsx"
import {FormHintExplanation} from "../../../models/contents.ts"
import {InputFromImage} from "../../misc/input-from-image.tsx"
import React from "react"
import {Thumbnail} from "../../misc/thumbnail.tsx";

function HintExplanationForm(props: {
  widgetId: string
  title: string
  value: FormHintExplanation | undefined
  onChange: (value: FormHintExplanation) => void
}) {
  const form: FormHintExplanation = props.value ?? {
    url: "",
    original: {
      w: 0,
      h: 0,
    },
    display: {
      w: 0,
    },
    text: "",
    voice_id: "",
    voice_text: "",
  }
  const setForm = (newForm: FormHintExplanation) => {
    props.onChange(newForm)
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="#9E9E9E">{props.title}</Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="url"
              value={form.url}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setForm({
                  ...form,
                  url: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
              {form.url && <Thumbnail url={form.url}/>}
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="original image width"
              type="number"
              value={form.original?.w ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (Number(event.target.value) < 0) {
                  return
                }
                setForm({
                  ...form,
                  original: {
                    ...form.original,
                    w: Number(event.target.value),
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="original image height"
              type="number"
              value={form.original?.h ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (Number(event.target.value) < 0) {
                  return
                }
                setForm({
                  ...form,
                  original: {
                    ...form.original,
                    h: Number(event.target.value),
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="width when displayed"
              type="number"
              value={form.display?.w ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (Number(event.target.value) < 0) {
                  return
                }
                setForm({
                  ...form,
                  display: {
                    w: Number(event.target.value),
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputFromImage
              onExtract={(value) => {
                setForm({
                  ...form,
                  original: {
                    w: value.w,
                    h: value.h,
                  },
                  display: {
                    w: value.w,
                  },
                  url: value.fileName,
                })
              }}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={6}>
            <TextField
              multiline
              fullWidth
              label="text"
              value={form.text}
              onChange={(event) => {
                setForm({
                  ...form,
                  text: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel control={
              <Checkbox
                checked={form.is_markdown ?? false}
                onChange={(event) => {
                  setForm({
                    ...form,
                    is_markdown: event.target.checked
                  })
                }}
              />
            } label="is markdown"/>
          </Grid>
          <Grid item xs={3}>
            <VoiceIdInput
              id={props.widgetId}
              value={form.voice_id ?? ""}
              onChange={(voice_id) => {
                setForm({
                  ...form,
                  voice_id: voice_id,
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              fullWidth
              label="voice text"
              value={form.voice_text}
              onChange={(event) => {
                setForm({
                  ...form,
                  voice_text: event.target.value,
                })
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default HintExplanationForm
