import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SelectAll(props: {
    id: string,
    allChecked: boolean,
    handleSelectAll: (arg0:boolean) => void,
  }){
    // when checkbox is clicked, flips the boolean checked/unchecked for all checkboxes
  const handleChange = () => {
    props.handleSelectAll(!props.allChecked)
  }
  return (
    <div>
      <FormControlLabel
        label="Select All"
        control={
          <Checkbox
            checked={props.allChecked}
            onChange={handleChange}
          />
        }
      />
    </div>
  );
}