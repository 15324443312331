import {ImageWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";
import React from "react";
import {InputFromImage} from "../misc/input-from-image.tsx";
import {VoiceIdInput} from "../misc/voice-id-input.tsx";
import {Thumbnail} from "../misc/thumbnail.tsx";

function ImageWidgetForm(props: {
    value: ImageWidget
    onChange: (value: Widget) => void
}) {
    if (props.value.form === undefined) {
        props.value.form = {
            url: props.value.parameters?.url || "",
            original: {
                w: 0,
                h: 0,
            },
            display: {
                w: 0,
            },
        }
        props.onChange(props.value)
        return <></>
    }
    // delete me: 移行用
    if(props.value.form.original === undefined) {
        props.value.form.original = {
            w: 0,
            h: 0,
        }
        props.value.form.display = {
            w: 0,
        }
        props.onChange(props.value)
        return <></>
    }
    return (
        <>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="type"
                    value={props.value.type}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={9}>
                <InputFromImage onExtract={(value) => {
                    const newValue = {...props.value}
                    newValue.form!.original.w = value.w
                    newValue.form!.original.h = value.h
                    newValue.form!.url = value.fileName
                    props.onChange(newValue)
                }}/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="url"
                    value={props.value.form?.url || props.value.parameters?.url}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = {...props.value}
                        newValue.form!.url = event.target.value
                        props.onChange(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                {props.value.parameters?.url && <Thumbnail url={props.value.parameters?.url}/>}
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    label="original image width"
                    type="number"
                    value={props.value.form?.original.w}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (Number(event.target.value) < 0) {
                            return
                        }
                        const newValue = {...props.value}
                        newValue.form!.original.w = Number(event.target.value)
                        props.onChange(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    label="original image height"
                    type="number"
                    value={props.value.form?.original.h}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (Number(event.target.value) < 0) {
                            return
                        }
                        const newValue = {...props.value}
                        newValue.form!.original.h = Number(event.target.value)
                        props.onChange(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="width when displayed"
                    type="number"
                    value={props.value.form?.display.w}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (Number(event.target.value) < 0) {
                            return
                        }
                        const newValue = {...props.value}
                        newValue.form!.display.w = Number(event.target.value)
                        props.onChange(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <VoiceIdInput
                    id={props.value.id}
                    value={props.value.form?.voice_id ?? ""}
                    onChange={(voice_id) => {
                        const newValue = {...props.value}
                        newValue.form!.voice_id = voice_id
                        props.onChange(newValue)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    multiline
                    fullWidth
                    label="text"
                    value={props.value.form?.text}
                    onChange={(event) => {
                        const newValue = {...props.value}
                        newValue.form!.text = event.target.value
                        props.onChange(newValue)
                    }}
                />
            </Grid>
        </>
    );
}

export default ImageWidgetForm;
