import {createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from "react";
import {Questions} from "../models/Questions.ts";
import {generateQuestionsId} from "../components/questions-generator/questions-generator.tsx";
import {Contents, Widget} from "../models/contents.ts";
import {isContents, isQuestions} from "../util/url-helper.ts";

export const TemplatesContext = createContext<{
    templates: Template[],
    setTemplates: Dispatch<SetStateAction<Template[]>>,
    addTemplateWidget: (index: number, template: Template) => void
}>({} as any)

export interface Template {
    title: string
    id: string
    widget: Widget
}

interface TemplatesProviderProps {
    contents: Contents | Questions,
    setContents: Dispatch<SetStateAction<Contents>> | Dispatch<SetStateAction<Questions>>,
    children: ReactNode;
}

export const TemplatesContextProvider: FC<TemplatesProviderProps> = ({contents, setContents, children}) => {
    const templateName = isContents(contents) ? "lessonTemplate" : "questionTemplate"

    const [templates, setTemplates] = useState<Template[]>(() => {
        const loaded = localStorage.getItem(templateName)
        if (loaded) {
            return JSON.parse(loaded) as Template[]
        }
        return []
    })
    useEffect(() => {
        localStorage.setItem(templateName, JSON.stringify(templates))
    })

    // when button is pressed, we add the selected Template as a widget at the given index (i + 1)
    const addTemplateWidget = (index: number, template: Template) => {
        const newContents = {...contents}
        newContents.widgets.splice(index, 0, {...template.widget, id: generateQuestionsId()})
        if (isContents(newContents)) {
            (setContents as Dispatch<SetStateAction<Contents>>)(newContents)
        } else if (isQuestions(newContents)) {
            (setContents as Dispatch<SetStateAction<Questions>>)(newContents)
        }
    }

    return (
        <TemplatesContext.Provider value={{templates, setTemplates, addTemplateWidget}}>
            {children}
        </TemplatesContext.Provider>
    );
}
